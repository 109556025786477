import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withResponsive, media } from 'util/Responsive';
import FitImage from 'components/FitImage';
import NoteAuthor from 'components/WorkPage/NoteAuthor';

import { translate } from 'util/Translator';

const NotesWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  ${media.tablet`
    padding: 30px 30px;
    width: 100%
  `}
  > :not(:first-child) {
    padding-top: 30px;
  }
`;

const NoteWrap = styled.div`
  width: 100%;
  max-width: 435px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
`;

const NoteTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5em;
  color: #222222;
  margin-bottom: 30px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin-bottom: 30px;
`;

const NoteExcerpt = styled.div`
  color: #4f4f4f;
  font-size: 13px;
  line-height: 1.8em;
  word-break: break-word;
`;

const ExplainText = styled.div`
  max-width: 435px;
  width: 100%;
  font-size: 13px;
  color: #222222;
  line-height: 1.7em;
  margin: 30px 0;
`;

const ReadMore = styled.div`
  font-size: 13px;
  line-height: 1.8em;
  color: #eb5757;
  text-decoration: underline;
  text-decoration-color: #eb5757;
`;

const NoteList = pure(
  translate('notes')(({ t, notes, workID, secret, onClickViewNote }) => (
    <NotesWrap>
      {notes.length === 0 && <ExplainText>{t('explain')}</ExplainText>}
      {notes.map(note => {
        const isLongNote = note.excerpt
          ? note.excerpt.substring(note.excerpt.length - 3) === '...'
          : false;
        const noteUrl = `/works/${workID}/notes/${note.id}${
          secret ? `?secret=${secret}` : ''
        }`;
        return (
          <NoteWrap key={note.id}>
            <Link to={noteUrl} onClick={onClickViewNote}>
              <NoteTitle>{note.title}</NoteTitle>
            </Link>
            {Boolean(note.thumbnail && note.thumbnail !== '') && (
              <Link to={noteUrl} onClick={onClickViewNote}>
                <FitImage
                  src={note.thumbnail}
                  customContainer={ThumbnailContainer}
                  alt={note.title}
                />
              </Link>
            )}
            <Link to={noteUrl} style={{ marginBottom: 20 }}>
              <NoteExcerpt>
                {isLongNote
                  ? note.excerpt.substring(0, note.excerpt.length - 3)
                  : note.excerpt}
                {isLongNote && <ReadMore>…{t('readMore')}</ReadMore>}
              </NoteExcerpt>
            </Link>
            <NoteAuthor note={note} />
          </NoteWrap>
        );
      })}
    </NotesWrap>
  )),
);

export default withResponsive(translate('notes')(NoteList));
