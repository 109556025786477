import React from 'react';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import LinesEllipsis from 'react-lines-ellipsis';
import styled from 'styled-components';
import Divider from 'components/Divider';
import WorkCredits from 'components/WorkPage/WorkCredits';
import WorkInfoNoteList from 'components/WorkPage/WorkInfoNoteList';
import ShareWorkOrNote from 'components/WorkPage/ShareWorkOrNote';
import { withResponsive } from 'util/Responsive';
import { translate } from 'util/Translator';

const WorkInfoContainer = styled.div`
  border-radius: 20px;
  padding: 35px 35px 20px;
  border-radius: 20px;
  position: relative;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px, rgba(0, 0, 0, 0.08) 0px 8px 16px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  line-height: 24px;
  color: #222222;
  word-break: break-word;
  margin: 0;
`;

const Description = styled.div`
  font-family: 'Noto Sans JP';
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  white-space: pre-wrap;
  margin-bottom: 48px;
  word-break: break-word;
  & .LinesEllipsis--clamped {
    cursor: pointer;
  }
  & .LinesEllipsis-ellipsis {
    color: #5863f8;
    font-weight: bold;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 20px 0;
`;

class WorkInfo extends React.Component {
  state = {
    isShowAllDescription: false,
  };

  handleOnClickDescription = () => {
    const { isShowAllDescription } = this.state;
    if (!isShowAllDescription) {
      this.setState({
        isShowAllDescription: true,
      });
    }
  };

  render() {
    const {
      work: { data, notesList },
      t,
      staticContext,
    } = this.props;
    const { isShowAllDescription } = this.state;
    return (
      <>
        <WorkInfoContainer>
          <Title>{data.title}</Title>
          <StyledDivider />
          {data.description && (
            <Description onClick={this.handleOnClickDescription}>
              {isShowAllDescription ? (
                <Linkify properties={{ target: '_blank' }}>
                  {data.description}
                </Linkify>
              ) : (
                <Linkify properties={{ target: '_blank' }}>
                  <LinesEllipsis
                    text={data.description}
                    maxLine={5}
                    ellipsis={`...${t('seeMore')}`}
                    basedOn="letters"
                  />
                </Linkify>
              )}
            </Description>
          )}
          <WorkCredits
            workOwnerId={data.owner_id}
            workId={data.id}
            credits={data.credits}
          />
          {notesList && !!notesList.length && (
            <WorkInfoNoteList work={data} notes={notesList} />
          )}
        </WorkInfoContainer>
        <ShareWorkOrNote work={data} staticContext={staticContext} />
      </>
    );
  }
}

export default translate('workPage')(
  withResponsive(
    connect(({ auth }) => ({
      auth,
    }))(WorkInfo),
  ),
);
