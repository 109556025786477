import React, { Component } from 'react';
import { Link, Route, Switch, withRouter, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import WorkInfo from 'components/WorkPage/WorkInfo';
import NoteList from 'components/WorkPage/NoteList';
import NoteView from 'components/WorkPage/NoteView';
import Divider from 'components/Divider';
import { translate } from 'util/Translator';

const Container = styled.div`
  background: #fff;
  position: relative;
  overflow: auto;
  width: 495px;
  padding: 80px 30px 30px 30px;
  box-sizing: border-box;
  height: calc(100vh - 100px);
`;

const NavigationContainer = styled.div`
  margin-bottom: 30px;
`;

const NavigationBackLink = styled(Link)`
  color: #5863f8;
`;

const NavigationBackLabel = styled.div`
  margin-left: 10px;
  font-weight: bold;
`;

const NavigationDivider = styled(Divider)`
  margin-top: 10px;
`;

class WorkDetailDesktop extends Component {
  scrollToTop = () => {
    if (this.container) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.container.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const { t, work, staticContext, location } = this.props;
    const isMatchWorkRoute = matchPath(location.pathname, {
      path: '/works/:work_id',
      exact: true,
      strict: false,
    });
    return (
      <Container ref={ref => (this.container = ref)}>
        {!isMatchWorkRoute && (
          <NavigationContainer>
            <NavigationBackLink
              to={`/works/${work.data.id}${
                work.data.secret ? `?secret=${work.data.secret}` : ''
              }`}
            >
              <FontAwesomeIcon
                icon={['fas', 'chevron-left']}
                color="#5863F8"
                size={16}
              />
              <NavigationBackLabel>{t('back')}</NavigationBackLabel>
            </NavigationBackLink>
            <NavigationDivider />
          </NavigationContainer>
        )}
        <Switch>
          <Route
            path="/works/:work_id"
            exact
            render={routeProps => (
              <WorkInfo
                work={work}
                onClickViewNote={this.scrollToTop}
                staticContext={staticContext}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/works/:work_id/notes/:note_id"
            exact
            render={routeProps => (
              <NoteView onClickViewNote={this.scrollToTop} {...routeProps} />
            )}
          />
          <Route
            path="/works/:work_id/notes"
            exact
            render={routeProps => (
              <NoteList
                workID={work.data.id}
                secret={work.data.secret}
                notes={work.notesList}
                onClickViewNote={this.scrollToTop}
                {...routeProps}
              />
            )}
          />
        </Switch>
      </Container>
    );
  }
}

export default withRouter(translate('workPage')(WorkDetailDesktop));
