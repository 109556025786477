import {
  NEW_NOTIFICATION,
  DESTROY_NOTIFICATION,
} from 'services/constants/actionTypes';

const initialNoteState = [];

export default function notifications(state = initialNoteState, action) {
  switch (action.type) {
    case NEW_NOTIFICATION:
      return [...state, action.data];
    case DESTROY_NOTIFICATION:
      return state.filter(notif => notif.id !== action.id);
    default:
      return state;
  }
}
