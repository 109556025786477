import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from 'util/Responsive';

const Container = styled.div`
  position: fixed;
  bottom: 105px;
  right: 20px;
  border-radius: 60px;
  text-align: center;
  background-color: #eb5757;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s;
  ${media.tablet`
    bottom: 14px;
    right: 15px;
  `}
  :hover {
    background-color: rgb(219, 80, 80);
  }
  :active {
    background-color: rgb(204, 73, 73);
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #fbf8f8;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 5px;
`;

const Badge = styled.div`
  display: flex;
  position: absolute;
  top: -10px;
  left: -5px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
`;

const BadgeText = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #fbf8f8;
`;

const FloatingButton = props => {
  const {
    className,
    children,
    onClick,
    badgeNumber,
    hideBadge,
    label,
    icon,
  } = props;
  const component = (
    <Container className={className} onClick={onClick}>
      {children || (
        <Fragment>
          <ButtonContainer>
            {icon && <IconContainer>{icon}</IconContainer>}
            <Label>{label}</Label>
          </ButtonContainer>
          {!hideBadge && (
            <Badge>
              <BadgeText>{badgeNumber}</BadgeText>
            </Badge>
          )}
        </Fragment>
      )}
    </Container>
  );

  return typeof document !== 'undefined'
    ? ReactDOM.createPortal(component, document.body)
    : null;
};

export default FloatingButton;
