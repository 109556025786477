import { useState, useEffect } from 'react';

// https://simbathesailor.dev/useismounted-ref-vs-state-approach%20/
const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return isMounted;
};

export default useIsMounted;
