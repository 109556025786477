/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import FitImage from 'components/FitImage';
import ShareWorkOrNote from 'components/WorkPage/ShareWorkOrNote';
import NoteAuthor from 'components/WorkPage/NoteAuthor';
import WorkNoteList from 'components/WorkPage/WorkNoteList';
import NoteMarkdownViewer from 'components/WorkPage/NoteMarkdownViewer';
import Divider from 'components/Divider';
import EditorJsViewer from 'components/EditorJsViewer';
import BasicLoader from 'components/BasicLoader';
import { media } from 'util/Responsive';
import { translate } from 'util/Translator';
import { getNote, getRelatedNotesList } from 'services/api';

const ModdedShareNote = styled(ShareWorkOrNote)`
  margin-top: 25px;
  margin-bottom: 0;
`;

const NoteWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  ${media.tablet`
    padding: 30px;
  `}
`;

const NoteInner = styled.div`
  width: 100%;
  max-width: 435px;
`;

const NoteTitle = styled.div`
  font-family: 'Noto Sans JP';
  font-weight: bold;
  line-height: 1.5em;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: #222222;
`;

const NoteDetails = styled.div`
  margin: 30px 0 0;
`;

const OtherNotesOrRelatedNotesTopDivider = styled(Divider)`
  margin: 60px 0 50px;
`;

const OtherNotesOrRelatedNotesTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #222222;
  margin-bottom: 17px;
`;

const FooterNoteWrap = styled.div`
  background: #4f4f4f;
  border-radius: 5px;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const RelatedNoteWorkTitleFromWork = styled.div`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #bdbdbd;
  margin-right: 7px;
`;

const RelatedNoteWorkTitle = styled.div`
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #fbfbfb;
`;

const RelatedNoteWorkTitleWrap = styled.div`
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`;

const FooterNoteInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FooterNoteThumbnail = styled.div`
  position: relative;
  width: 126px;
  height: 70px;
  margin-right: 10px;
`;

const FooterNoteInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ useLeftPad }) =>
    useLeftPad &&
    css`
      margin-left: 10px;
    `}
`;

const FooterNoteTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  color: white;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.5em;
`;

const getData = (dispatch, workId, noteId, secret) =>
  getNote(dispatch, workId, noteId, secret);

export const getServerData = props => {
  const { dispatch, match, queryString } = props;
  const { secret } = queryString;
  const matchParams = (match || {}).params || {};
  return getData(dispatch, matchParams.work_id, matchParams.note_id, secret);
};

class NoteView extends React.Component {
  static getInitialData(ctx) {
    const { match, dispatch, queryString } = ctx;
    const { work_id, note_id } = match.params;
    const { secret } = queryString;
    return getNote(dispatch, work_id, note_id, secret).then(() => {
      return {
        match,
      };
    });
  }

  componentDidMount() {
    const {
      initialData,
      note_id,
      work_id,
      work,
      dispatch,
      location,
      match,
    } = this.props;
    // Skip fetching if data fetched from server side
    if (!initialData || initialData.note_id !== note_id) {
      const queryString = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      this.constructor.getInitialData({
        match,
        dispatch,
        queryString,
      });
    }

    if (work && work.data.notes_count === 1) {
      getRelatedNotesList(dispatch, work_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { note_id, work_id, dispatch } = this.props;
    const { note_id: prevNoteId } = prevProps;
    if (note_id !== prevNoteId) {
      getNote(dispatch, work_id, note_id);
    }
  }

  noteDetails = () => {
    const { note } = this.props;
    if (note.data.body_json?.blocks?.length) {
      return <EditorJsViewer data={note.data.body_json} />;
    }
    return <NoteMarkdownViewer data={note.data.body_md} />;
  };

  renderOtherNoteOrRelatedNote = (
    t,
    isShowRelatedNote,
    note,
    work,
    onClickViewNote,
  ) => (
    <Link
      to={`/works/${work.id}/notes/${note.id}`}
      onClick={onClickViewNote}
      key={note.id}
    >
      <FooterNoteWrap>
        {isShowRelatedNote ? (
          <RelatedNoteWorkTitleWrap>
            <RelatedNoteWorkTitleFromWork>
              {t('fromWork')}
            </RelatedNoteWorkTitleFromWork>
            <RelatedNoteWorkTitle>{work.title}</RelatedNoteWorkTitle>
          </RelatedNoteWorkTitleWrap>
        ) : null}
        <FooterNoteInfoWrap>
          {!!note.thumbnail && (
            <FitImage
              src={note.thumbnail}
              customContainer={FooterNoteThumbnail}
              alt={note.title}
            />
          )}
          <FooterNoteInfo useLeftPad={!!note.thumbnail}>
            <FooterNoteTitle>{note.title}</FooterNoteTitle>
            <NoteAuthor note={note} />
          </FooterNoteInfo>
        </FooterNoteInfoWrap>
      </FooterNoteWrap>
    </Link>
  );

  render() {
    const {
      work,
      note: { loading, data },
    } = this.props;
    if (loading) {
      return <BasicLoader />;
    }
    return (
      <NoteWrap>
        {loading && <BasicLoader />}
        {!loading && data && (
          <Fragment>
            <Helmet>
              <title>{`${data.title}`}</title>
              <meta name="description" content={`${data.meta_desc}`} />
              <meta property="og:title" content={`${data.title}`} />
              <meta property="og:description" content={`${data.meta_desc}`} />
              <meta property="og:image" content={`${data.meta_image}`} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`${data.title}`} />
              <meta name="twitter:text:title" content={`${data.title}`} />
              <meta name="twitter:description" content={`${data.meta_desc}`} />
              <meta name="twitter:image" content={`${data.meta_image}`} />
            </Helmet>
            <NoteInner>
              <NoteTitle>{data.title}</NoteTitle>
              <NoteAuthor note={data} />
              <NoteDetails>{this.noteDetails()}</NoteDetails>
              <Divider />
              <ModdedShareNote work={work.data} note={data} />
            </NoteInner>
          </Fragment>
        )}
      </NoteWrap>
    );
  }
}

export default translate('notes')(
  connect(({ notes, works }, props) => {
    const { work_id, note_id } = props.match.params;

    const note = {
      data: { details: '', user: { profile: {} } },
      loading: true,
      ...notes[`${work_id}:${note_id}`],
    };

    const noteList = (works[work_id] || {}).notesList || [];
    const otherNoteList = noteList.filter(
      ({ id }) => id !== parseInt(note_id, 10),
    );
    const relatedNoteList = (works[work_id] || {}).relatedNotesList || [];

    return {
      otherNoteList,
      relatedNoteList,
      work_id,
      note_id,
      note,
      work: works[work_id] || {},
      ...props.initialData,
    };
  })(NoteView),
);
