import React from 'react';
import BaseError from 'components/ErrorPage/BaseError';
import { translate } from 'util/Translator';

const Error404 = ({ staticContext, t }) => {
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.statusCode = 404;
  }
  return (
    <BaseError
      title={t('404Title')}
      description={t('404Description')}
      staticContext={staticContext}
    >
      <svg
        width="100%"
        height="258"
        viewBox="0 0 376 258"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M187.6 257.5C291.209 257.5 375.2 239.188 375.2 216.6C375.2 194.012 291.209 175.7 187.6 175.7C83.9914 175.7 0 194.012 0 216.6C0 239.188 83.9914 257.5 187.6 257.5Z"
          fill="#D7DBFF"
        />
        <path
          d="M321.9 1.5H50.5V200.8H321.9V1.5Z"
          fill="white"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M243 27.7H130V140.7H243V27.7Z"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M231.5 38.2H140.5V129.2H231.5V38.2Z"
          fill="#E7EEFF"
          stroke="#5863F8"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M29.4004 153.1C29.4004 153.1 61.6004 203.1 133.9 170.3"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M342.8 153.1C342.8 153.1 310.6 203.1 238.3 170.3"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M238.4 170.2C238.4 170.2 186.6 204.7 133.9 170.2"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M342.8 153.1C347.108 153.1 350.6 149.608 350.6 145.3C350.6 140.992 347.108 137.5 342.8 137.5C338.492 137.5 335 140.992 335 145.3C335 149.608 338.492 153.1 342.8 153.1Z"
          fill="#5863F8"
        />
        <path
          d="M342.8 153.1V222.1"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M133.9 170.3C138.207 170.3 141.7 166.808 141.7 162.5C141.7 158.192 138.207 154.7 133.9 154.7C129.592 154.7 126.1 158.192 126.1 162.5C126.1 166.808 129.592 170.3 133.9 170.3Z"
          fill="#5863F8"
        />
        <path
          d="M133.899 170.2V239.2"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M238.4 170.3C242.707 170.3 246.2 166.808 246.2 162.5C246.2 158.192 242.707 154.7 238.4 154.7C234.092 154.7 230.6 158.192 230.6 162.5C230.6 166.808 234.092 170.3 238.4 170.3Z"
          fill="#5863F8"
        />
        <path
          d="M238.399 170.2V239.2"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M29.3996 153.1C33.7074 153.1 37.1996 149.608 37.1996 145.3C37.1996 140.992 33.7074 137.5 29.3996 137.5C25.0918 137.5 21.5996 140.992 21.5996 145.3C21.5996 149.608 25.0918 153.1 29.3996 153.1Z"
          fill="#5863F8"
        />
        <path
          d="M29.3994 153.1V222.1"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M168.555 96H172.957V91.722H175.623V87.847H172.957V75.323H166.881L158.666 88.25V91.722H168.555V96ZM168.555 79.229V87.847H162.975L168.555 79.229ZM177.442 85.677C177.442 91.009 180.077 96.372 185.998 96.372C191.888 96.372 194.554 91.009 194.554 85.677C194.554 80.345 191.888 75.013 185.998 75.013C180.077 75.013 177.442 80.345 177.442 85.677ZM190.059 85.677C190.059 89.49 188.943 92.466 185.998 92.466C183.022 92.466 181.906 89.49 181.906 85.677C181.906 81.864 183.022 78.919 185.998 78.919C188.943 78.919 190.059 81.864 190.059 85.677ZM206.276 96H210.678V91.722H213.344V87.847H210.678V75.323H204.602L196.387 88.25V91.722H206.276V96ZM206.276 79.229V87.847H200.696L206.276 79.229Z"
          fill="#5863F8"
        />
      </svg>
    </BaseError>
  );
};

export default translate('error')(Error404);
