import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';
import SingleWork from 'components/UserPage/SingleWork';
import BasicLoader from 'components/BasicLoader';
import { media, withResponsive } from 'util/Responsive';
import { translate } from 'util/Translator';

const WorksContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  ${({ disableWorks }) =>
    disableWorks &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
  ${media.tablet`
    margin-left: -8px;
    margin-right: -8px;
  `}
  ${({ horizontal }) =>
    horizontal &&
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `}
  margin-bottom: ${({ hasPrivateWorks }) => (hasPrivateWorks ? '250px' : 0)};
`;

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  transform: translateZ(0);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  width: 100%;
`;

class WorkGrid extends Component {
  renderItem = ({ item, index }) => {
    const { dashboard, horizontal, chosenTextColor } = this.props;
    return (
      <SingleWork
        key={item.id}
        index={index}
        id={item.id}
        work={item}
        dashboard={dashboard}
        horizontal={horizontal}
        chosenTextColor={chosenTextColor}
      />
    );
  };

  handleOnScrollHorizontal = e => {
    const { hasMore, loadMore, loading } = this.props;
    const { scrollWidth, offsetWidth, scrollLeft } = e.target;
    if (hasMore && !loading && scrollWidth - offsetWidth <= scrollLeft + 100) {
      loadMore();
    }
  };

  render() {
    const {
      hasMore,
      loadMore,
      loading,
      horizontal,
      hasPrivateWorks,
      data,
    } = this.props;
    return (
      <WorksContainer horizontal={horizontal} hasPrivateWorks={hasPrivateWorks}>
        {horizontal ? (
          <HorizontalScrollContainer onScroll={this.handleOnScrollHorizontal}>
            {data.map((item, index) => this.renderItem({ item, index }))}
            {loading && <BasicLoader />}
          </HorizontalScrollContainer>
        ) : (
          <InfiniteScroll
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<BasicLoader />}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              overflow: 'visible',
            }}
          >
            {data.map((item, index) => this.renderItem({ item, index }))}
          </InfiniteScroll>
        )}
      </WorksContainer>
    );
  }
}

export default connect(({ auth }) => ({ auth }))(
  translate('dashboard')(withResponsive(WorkGrid)),
);
