import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import copy from 'copy-to-clipboard';
import OutsideClickHandler from 'react-outside-click-handler';
import { toast } from 'react-toastify';
import { WhiteButton } from 'components/Buttons';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { trackEvent } from 'util/analytics';
import { translate } from 'util/Translator';
import { media, withResponsive, deviceSizes } from 'util/Responsive';

const Container = styled.div`
  position: relative;
  margin-right: ${({ noMarginRight }) => (noMarginRight ? '0' : '10px;')};
`;

const animationStart = 250;
const StyledButton = styled(WhiteButton)`
  color: #4f4f4f;
  padding: ${({ circleButton }) => (circleButton ? '10px' : '7px 15px')};
  font-size: 12px;
  transition: all 0.4s ease-in-out;

  ${({ isInGlassyHeader }) =>
    isInGlassyHeader &&
    css`
      min-width: 30px;
      min-height: 30px;
      width: 30px;
      height: 30px;
      padding: 0;

      ${media.tablet`
          min-width: 30px;
          min-height: 30px;  
          max-height: 48px;

          color: unset;
          color:  ${({ scrollPosition }) =>
            scrollPosition > animationStart - 20
              ? '#4F4F4F'
              : '#FFFFFF'} !important;
            
          font-size: ${({ scrollPosition }) =>
            scrollPosition > animationStart - 50 ? '10px' : '13px'};
            
          margin-right: ${({ scrollPosition }) =>
            scrollPosition > animationStart ? '0px' : '-5px'};

          border:  ${({ rgbValues, scrollPosition }) =>
            rgbValues && scrollPosition < animationStart
              ? `solid 1px rgba( ${rgbValues?.r - 10}, ${rgbValues?.g -
                  10}, ${rgbValues?.b - 10}, 0.5)`
              : 'none'}

          transform: ${({ scrollPosition }) =>
            scrollPosition > animationStart
              ? 'translateX(0)'
              : 'translateX(10px)'};

          height: ${({ scrollPosition }) =>
            scrollPosition > animationStart ? '30px' : '50px'};

          width: ${({ scrollPosition }) =>
            scrollPosition > animationStart ? '30px' : '50px'};

          padding: ${({ scrollPosition, circleButton }) =>
            circleButton && scrollPosition > animationStart
              ? '0 10px 1px'
              : '10px 0'};

          background-color: ${({ scrollPosition, rgbValues }) =>
            scrollPosition > animationStart
              ? 'rgba(242, 242, 242, 0.9)'
              : `rgba(${rgbValues?.r - 20}, ${rgbValues?.g -
                  20}, ${rgbValues?.b - 20}, 0.3)`};
            }
        `}
    `};
`;

const MenuListContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 55px;
  right: 0px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 245px;

  ${media.tablet`
    left: ${({ isInGlassyHeader }) => (isInGlassyHeader ? '-280px' : '-98px')};
    width: 326px;
  `}

  ${media.phone`
    left: ${({ isInGlassyHeader }) => (isInGlassyHeader ? '-215px' : '-98px')};
    width: 250px;
  `}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #e5e5e5')};
`;

const MenuText = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #4f4f4f;
  margin-left: 10px;
`;

const SquareTwitterIcon = styled.img`
  width: 16px;
  height: 18px;
`;

const Menu = ({ icon, text, onClick }) => {
  return (
    <MenuContainer onClick={onClick}>
      {icon}
      <MenuText>{text}</MenuText>
    </MenuContainer>
  );
};

const ShareProfileDropdownButton = ({
  className,
  t,
  profileUrl,
  circleButton,
  noMarginRight,
  isInGlassyHeader,
  scrollPosition,
  rgbValues,
  chosenTextColor,
  isTeamDashboard,
  appWidth,
}) => {
  // const dispatch = useDispatch();
  const isMobile = appWidth <= deviceSizes.tablet;
  // const location = useLocation();
  const [isToggle, toggle] = useState(false);
  // const auth = useSelector(state => state.auth);
  // const user = useSelector(
  //   state => state.users[(auth.user?.screen_name)]?.data,
  // );

  const copyProfileUrl = () => {
    copy(profileUrl);
    toast('Copied URL');
  };

  const getProfileUrl = () => {
    return `url=${profileUrl}&hashtags=foriio,portfolio,work,resume`;
  };
  // const isCurrentUser = auth?.user?.id === userPageUser?.data?.user?.id;
  const handleOnClickCopyUrl = () => {
    toggle(false);
    trackEvent({
      action: 'Clicked to copy profile url',
      label: profileUrl,
    });
    copyProfileUrl();
  };

  const handleOnClickShareToTwitter = () => {
    toggle(false);
    trackEvent({
      event: 'GAEvent',
      category: 'share-to-twitter-btn-clicked',
      action: 'Clicked share to twitter',
      label: profileUrl,
    });
    window.open(`https://www.twitter.com/intent/tweet?${getProfileUrl()}`);
  };

  const handleOnClickShareToFacebook = () => {
    toggle(false);
    trackEvent({
      event: 'GAEvent',
      category: 'share-to-facebook-btn-clicked',
      action: 'Clicked share to facebook',
      label: profileUrl,
    });
    const facebookShareURL = new URL(
      `https://www.facebook.com/sharer/sharer.php?u=${profileUrl}`,
    );

    window.open(facebookShareURL);
  };

  const handleOnToggleShare = () => {
    if (!isToggle) {
      trackEvent({ action: 'Clicked share' });
    }
    toggle(!isToggle);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isToggle) {
          toggle(false);
        }
      }}
    >
      <Container className={className} noMarginRight={noMarginRight}>
        <StyledButton
          fullyRound
          circleButton={circleButton}
          isInGlassyHeader={isInGlassyHeader}
          scrollPosition={scrollPosition}
          rgbValues={rgbValues}
          chosenTextColor={chosenTextColor}
          text={!circleButton && t('share')}
          icon={
            <FontAwesomeIcon
              icon={faShare}
              color="#2E2E2E"
              size={scrollPosition < 340 && isMobile ? 18 : 15}
              inverse={isMobile && scrollPosition < 340}
            />
          }
          onClick={handleOnToggleShare}
          id="shareProfileButton"
        />
        {isToggle && (
          <MenuListContainer isInGlassyHeader={isInGlassyHeader}>
            <Menu
              text={t('copyUrl')}
              icon={
                <FontAwesomeIcon icon={faShare} color="#4F4F4F" size={18} />
              }
              onClick={handleOnClickCopyUrl}
            />
            {!isTeamDashboard && (
              <>
                <Menu
                  text={t('shareToTwitter')}
                  icon={
                    <SquareTwitterIcon src="https://foriio-production-v3.s3.us-west-1.amazonaws.com/public/ginrnzMkYyBI_1669385152-" />
                  }
                  onClick={handleOnClickShareToTwitter}
                />
                <Menu
                  text={t('shareToFacebook')}
                  icon={
                    <FontAwesomeIcon
                      icon={faFacebook}
                      color="#4F4F4F"
                      size={18}
                    />
                  }
                  onClick={handleOnClickShareToFacebook}
                />
              </>
            )}
          </MenuListContainer>
        )}
      </Container>
    </OutsideClickHandler>
  );
};

export default translate('userPage')(
  withResponsive(memo(ShareProfileDropdownButton)),
);
