import React from 'react';
import styled from 'styled-components';
import FitImage from 'components/FitImage';

const CircleAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ size }) => size || 30}px;
  height: ${({ size }) => size || 30}px;
  width: ${({ size }) => size || 30}px;
  overflow: hidden;
`;

export default ({ image, ...rest }) => (
  <CircleAvatarWrapper {...rest}>
    <FitImage
      src={
        image ||
        'https://dyci7co52mbcc.cloudfront.net/static/images/default_avatar.jpg'
      }
    />
  </CircleAvatarWrapper>
);
