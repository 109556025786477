import React from 'react';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { destroyNotification } from 'components/Notifications/notifActions';
import { translate } from 'util/Translator';

const NotificationsAnchor = styled.div`
  z-index: 999999;
  position: fixed;
  top: 10px;
  right: 20px;
`;

const NotificationsWrapper = styled.div`
  position: absolute;
  right: 0;
`;

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 80vw;
  padding: 20px;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  background-color: white;
  margin-top: 10px;
`;

const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  color: #4f4f4f;
  font-size: 19px;
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
`;

const Touchable = styled.div`
  cursor: pointer;
`;

const Icons = {
  success: () => (
    <FontAwesomeIcon
      icon={['fas', 'check-circle']}
      color="rgb(11, 207, 151)"
      size={38}
    />
  ),
  error: () => (
    <FontAwesomeIcon
      icon={['fas', 'times-circle']}
      color="rgb(235, 87, 87)"
      size={38}
    />
  ),
  info: () => (
    <FontAwesomeIcon
      icon={['fas', 'info-circle']}
      color="rgb(242, 153, 74)"
      size={38}
    />
  ),
};

const Icon = ({ type }) => {
  const ChosenIcon = Icons[type] || (() => null);
  return <ChosenIcon />;
};

const Notifications = ({ notifications, dispatch, t }) => (
  <NotificationsAnchor>
    <NotificationsWrapper>
      {notifications.map(notif => (
        <Touchable
          key={notif.id}
          onClick={() => destroyNotification(dispatch, notif.id)}
        >
          <NotificationWrapper>
            {notif.type && <Icon type={notif.type} />}
            <NotificationText>{t(notif.message)}</NotificationText>
          </NotificationWrapper>
        </Touchable>
      ))}
    </NotificationsWrapper>
  </NotificationsAnchor>
);

export default pure(
  translate('notifications')(
    connect(({ notifications }) => ({ notifications }))(Notifications),
  ),
);
