import {
  USER_PROFILE_FETCH,
  USER_PROFILE_LOAD,
  USER_PROFILE_ERROR,
  USER_WORKS_FETCH,
  USER_WORKS_LOAD,
  USER_WORKS_ERROR,
  USER_WORKS_CLEAR,
  USER_WORKS_CATEGORIES_FETCH,
  USER_WORKS_CATEGORIES_LOAD,
  USER_WORKS_CATEGORIES_ERROR,
  SINGLE_WORK_FETCH,
  SINGLE_WORK_LOAD,
  SINGLE_WORK_ERROR,
  SINGLE_WORK_NOTESLIST_FETCH,
  SINGLE_WORK_NOTESLIST_LOAD,
  SINGLE_WORK_NOTESLIST_ERROR,
  RELATED_NOTESLIST_FETCH,
  RELATED_NOTESLIST_LOAD,
  RELATED_NOTESLIST_ERROR,
  NOTE_FETCH,
  NOTE_LOAD,
  NOTE_ERROR,
  PRIVATE_USER_SESSION_REQUEST,
  PRIVATE_USER_SESSION_SUCCESS,
  PRIVATE_USER_SESSION_ERROR,
} from 'services/constants/actionTypes';
import { getCookies } from 'util/cookies';

const apiURL = `${process.env.RAZZLE_API_BASE_URL}/api/v1/`;

const fetchJSON = (url, opts) => {
  const finalOptions = {
    ...opts,
    headers: {
      'Content-Type':
        opts.method !== 'DELETE' && (opts.headers || {})['Content-Type'] !== ''
          ? (opts.headers || {})['Content-Type'] || 'application/json'
          : undefined,
      ...(opts || {}).headers,
    },
  };
  const cookies = getCookies();
  if (cookies.get('p-token')) {
    finalOptions.headers['p-token'] = cookies.get('p-token');
  }
  // eslint-disable-next-line no-undef
  return fetch(url, finalOptions).then(resp => {
    if (!resp.ok) {
      return resp.json().then(r => Promise.reject(r));
    }
    if (resp.status === 204) {
      return resp.text();
    }
    return resp.json();
  });
};

const apiRequest = (path, opts, dispatch) => {
  const { params, ...restOpts } = opts || {};

  const url = `${
    (opts || {}).noVersion ? `${process.env.RAZZLE_API_BASE_URL}/` : apiURL
  }${path}`;
  return fetchJSON(url, restOpts, dispatch);
};

export const getUserProfile = (dispatch, userID) => {
  dispatch({ type: USER_PROFILE_FETCH, userID });
  return apiRequest(`wl/users/${userID}/profile`, {}, dispatch)
    .then(({ profile }) => {
      dispatch({ type: USER_PROFILE_LOAD, userID, data: profile || {} });
    })
    .catch(error => {
      dispatch({ type: USER_PROFILE_ERROR, userID, error });
      console.warn(`=== USER ${userID} PROFILE FETCH ERROR ===`);
      console.error(error);
    });
};

export const getUserWorks = (
  dispatch,
  userID,
  page = 1,
  category,
  perPage = 20,
) => {
  if (page === 1) {
    dispatch({ type: USER_WORKS_CLEAR, userID });
  }
  dispatch({
    type: USER_WORKS_FETCH,
    userID,
    page,
    category,
  });
  return apiRequest(
    `wl/users/${userID}/works?page=${page}&per_page=${perPage}${
      category ? `&category=${category}` : ''
    }`,
    {},
    dispatch,
  )
    .then(({ works, meta }) => {
      dispatch({
        type: USER_WORKS_LOAD,
        userID,
        works: works || [],
        worksMeta: meta,
      });
    })
    .catch(error => {
      dispatch({ type: USER_WORKS_ERROR, userID });
      console.warn(`=== USER ${userID} WORKS FETCH ERROR ===`);
      console.error(error);
    });
};

export const getUserWorksCategories = (dispatch, userID) => {
  dispatch({ type: USER_WORKS_CATEGORIES_FETCH, userID });
  return apiRequest(`wl/users/${userID}/categories`, {}, dispatch)
    .then(({ categories }) => {
      dispatch({
        type: USER_WORKS_CATEGORIES_LOAD,
        userID,
        data: categories || [],
      });
    })
    .catch(error => {
      dispatch({ type: USER_WORKS_CATEGORIES_ERROR, userID, error });
      console.warn(`=== USER ${userID} WORKS CATEGORIES FETCH ERROR ===`);
      console.error(error);
    });
};

export const getSingleWork = (dispatch, userID, workID, secret) => {
  dispatch({ type: SINGLE_WORK_FETCH, workID });
  return apiRequest(
    `wl/users/${userID}/works/${workID}${secret ? `?secret=${secret}` : ''}`,
    {},
    dispatch,
  )
    .then(({ work }) => {
      dispatch({ type: SINGLE_WORK_LOAD, workID, data: work || {} });
    })
    .catch(error => {
      dispatch({ type: SINGLE_WORK_ERROR, workID, error });
      console.warn(`=== WORK ${workID} FETCH ERROR ===`);
      console.error(error);
    });
};

export const getSingleWorkNotesList = (dispatch, workID, secret) => {
  dispatch({ type: SINGLE_WORK_NOTESLIST_FETCH, workID });
  return apiRequest(
    `works/${workID}/notes${secret ? `?secret=${secret}` : ''}`,
    {},
    dispatch,
  )
    .then(({ notes }) => {
      dispatch({ type: SINGLE_WORK_NOTESLIST_LOAD, workID, data: notes || {} });
    })
    .catch(error => {
      dispatch({ type: SINGLE_WORK_NOTESLIST_ERROR, workID });
      console.warn(`=== WORK ${workID} NOTES LIST FETCH ERROR ===`);
      console.error(error);
    });
};

export const getRelatedNotesList = (dispatch, workID) => {
  dispatch({ type: RELATED_NOTESLIST_FETCH, workID });
  return apiRequest(`works/${workID}/related-notes`, {}, dispatch)
    .then(({ notes }) => {
      dispatch({ type: RELATED_NOTESLIST_LOAD, workID, data: notes || {} });
    })
    .catch(error => {
      dispatch({ type: RELATED_NOTESLIST_ERROR, workID });
      console.warn(`=== WORK ${workID} RELATED NOTES LIST FETCH ERROR ===`);
      console.error(error);
    });
};

export const getNote = (dispatch, workID, noteID, secret) => {
  dispatch({ type: NOTE_FETCH, workID, noteID });
  return apiRequest(
    `notes/${noteID}${secret ? `?secret=${secret}` : ''}`,
    {},
    dispatch,
  )
    .then(({ note }) => {
      dispatch({
        type: NOTE_LOAD,
        workID,
        noteID,
        data: note || {},
      });
    })
    .catch(error => {
      dispatch({ type: NOTE_ERROR, workID, noteID });
      console.warn(`=== NOTE work:${workID} note:${noteID} FETCH ERROR ===`);
      console.error(error);
    });
};

export const submitPrivateUserSession = (dispatch, userID, password) => {
  dispatch({ type: PRIVATE_USER_SESSION_REQUEST });
  return apiRequest(
    `wl/users/${userID}/sessions`,
    {
      method: 'POST',
      body: JSON.stringify({ password }),
    },
    dispatch,
  )
    .then(({ token }) => {
      dispatch({
        type: PRIVATE_USER_SESSION_SUCCESS,
        data: token,
      });
      return Promise.resolve(token);
    })
    .catch(error => {
      dispatch({ type: PRIVATE_USER_SESSION_ERROR });
      console.warn('=== SUBMIT PRIVATE USER SESSION ERROR ===');
      console.error(error);
      throw error;
    });
};
