import {
  USER_PROFILE_FETCH,
  USER_PROFILE_LOAD,
  USER_PROFILE_ERROR,
  USER_PROFILE_CLEAR,
  USER_WORKS_FETCH,
  USER_WORKS_LOAD,
  USER_WORKS_ERROR,
  USER_WORKS_CLEAR,
  USER_WORKS_CATEGORIES_LOAD,
} from 'services/constants/actionTypes';

import checkSavedUserColorConfig from '../../../util/checkSavedUserColorConfig';
import hexToRgb from '../../../util/convertHexToRgb';

const initialState = {
  loading: false,
  worksLoading: false,
  worksLoaded: false,
  data: { user: {} },
  workIDs: [],
  chosenTextColor: '',
  hexBackgroundColor: '',
  userThemeSelected: '',
  rgbValues: '',
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_FETCH: {
      const { userID } = action;
      return {
        ...state,
        userID,
        loading: true,
      };
    }
    case USER_PROFILE_LOAD: {
      const { data } = action;
      const {
        textColorFromBackground: chosenTextColor,
        savedBackgroundColor: hexBackgroundColor,
        userThemeSelected,
      } = checkSavedUserColorConfig(data);

      return {
        ...state,
        data,
        loading: false,
        chosenTextColor,
        hexBackgroundColor,
        userThemeSelected,
        rgbValues: hexToRgb(hexBackgroundColor),
      };
    }
    case USER_PROFILE_ERROR: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case USER_PROFILE_CLEAR: {
      return {
        ...state,
        error: null,
      };
    }
    case USER_WORKS_FETCH: {
      return {
        ...state,
        worksLoading: true,
      };
    }
    case USER_WORKS_LOAD: {
      const workIDs = action.works.map(work => work.id);
      const stateWorkIDs = state.workIDs ? state.workIDs : [];
      return {
        ...state,
        workIDs: [...stateWorkIDs, ...workIDs],
        worksMeta: action.worksMeta,
        worksLoading: false,
        worksLoaded: true,
      };
    }
    case USER_WORKS_ERROR: {
      return {
        ...state,
        worksLoading: false,
        worksLoaded: true,
      };
    }
    case USER_WORKS_CLEAR: {
      return {
        ...state,
        workIDs: [],
        worksMeta: null,
        worksLoaded: false,
      };
    }
    case USER_WORKS_CATEGORIES_LOAD: {
      const worksCategoriesIDs = action.data.map(category => category.id);
      return {
        ...state,
        worksCategoriesIDs,
        worksCategoriesLoading: false,
        worksCategoriesLoaded: true,
      };
    }
    default:
      return state;
  }
}
