import React from 'react';
import Modal from 'components/ResponsiveModal';
import Linkify from 'react-linkify';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import styled from 'styled-components';
import { media } from 'util/Responsive';

const Container = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin: 10px;
  width: 600px;
  ${media.tablet`
    width: auto;
  `}
`;

const SubContainer = styled.div`
  padding: 30px;
`;

const ModalTitle = styled.div`
  font-size: 28px;
  line-height: 28px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  margin: 0 30px 30px;
  ${media.tablet`
    width: auto;
  `}
`;

const StyledScrollView = styled.div`
  height: 318px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ${media.tablet`
    width: auto;
  `}
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 27px;
  color: #828282;
  white-space: pre-wrap;
  word-break: break-word;
`;

const CancelButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
`;

const CancelButton = ({ onPress }) => (
  <CancelButtonContainer onClick={onPress}>
    <FontAwesomeIcon icon={['fas', 'times']} color="#BDBDBD" size={24} />
  </CancelButtonContainer>
);

const AboutModal = ({ title, description, isVisible, onClose }) => (
  <Modal isVisible={isVisible} onBackdropPress={onClose}>
    <Container>
      <SubContainer>
        <ModalTitle>{title}</ModalTitle>
        <StyledScrollView>
          <Linkify properties={{ target: '_blank' }}>
            <Description>{description}</Description>
          </Linkify>
        </StyledScrollView>
        <CancelButton onPress={onClose} />
      </SubContainer>
    </Container>
  </Modal>
);

export default AboutModal;
