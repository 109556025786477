import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import FilterCategories from 'components/UserPage/FilterCategories';
import WorkGrid from 'components/UserPage/WorkGrid';
import { withResponsive, deviceSizes } from 'util/Responsive';
import BasicLoader from 'components/BasicLoader';

class Works extends Component {
  constructor(props) {
    super(props);
    const { category, worksCategories } = props;
    this.myRef = React.createRef();
    this.state = {
      leftArrow: null,
      rightArrow: null,
      selectedCategory:
        worksCategories && worksCategories.length
          ? worksCategories.find(c => decodeURI(c.slug) === category)
          : null,
      category,
    };
  }

  componentDidMount() {
    const { rightArrow } = this.state;
    if (this.myRef && this.myRef.current && rightArrow === null) {
      this.setState({
        rightArrow:
          this.myRef.current.clientWidth < this.myRef.current.scrollWidth,
      });
    }
  }

  componentDidUpdate() {
    const { category, worksCategories, onChangeCategory } = this.props;
    const { category: cat, rightArrow } = this.state;
    if (this.myRef && this.myRef.current && rightArrow === null) {
      this.setState({
        rightArrow:
          this.myRef.current.clientWidth < this.myRef.current.scrollWidth,
      });
    }
    if (cat !== category) {
      const selectedCategory =
        worksCategories && worksCategories.length
          ? worksCategories.find(c => decodeURI(c.slug) === category)
          : null;
      this.setState({
        selectedCategory,
        category,
      });
      onChangeCategory(selectedCategory);
    }
  }

  handleOnClickAll = () => {
    this.handleOnClickCategory(null);
  };

  handleOnClickCategory = category => {
    const { onChangeCategory } = this.props;
    this.setState({
      selectedCategory: category,
      category: category ? category.slug : null,
    });
    onChangeCategory(category);
  };

  handleOnScroll = () => {
    const { clientWidth, scrollWidth, scrollLeft } = this.myRef.current;
    const startOfScroll = scrollLeft === 0;
    const middleOfScroll =
      scrollLeft > 0 && clientWidth + scrollLeft + 22 < scrollWidth;
    const endOfScroll = clientWidth + scrollLeft + 22 >= scrollWidth;

    this.setState({
      leftArrow: middleOfScroll || endOfScroll,
      rightArrow: startOfScroll || middleOfScroll,
    });
  };

  render() {
    const {
      user,
      works,
      worksCategories,
      totalPages,
      hasMore,
      loadMore,
      hideCategories,
      hasPrivateWorks,
      dashboard,
      userScreenName,
      onDeleteComplete,
      appWidth,
      chosenTextColor,
    } = this.props;
    const { leftArrow, rightArrow, selectedCategory } = this.state;
    const filteredWorks =
      selectedCategory && totalPages === 1 && dashboard
        ? works.filter(w =>
            w.category_list.some(c => c === selectedCategory.name),
          )
        : works;
    const mappedWorkCategories = worksCategories.map(cat => ({
      ...cat,
      to: `/categories/${cat.slug}`,
      active: selectedCategory ? cat.slug === selectedCategory.slug : false,
    }));
    return (
      <div>
        {!(dashboard && appWidth <= deviceSizes.tablet) &&
          mappedWorkCategories &&
          mappedWorkCategories.length > 0 &&
          !hideCategories && (
            <FilterCategories
              data={mappedWorkCategories}
              onClickAll={this.handleOnClickAll}
              onClickCategory={this.handleOnClickCategory}
              selectedCategory={selectedCategory}
              onScroll={this.handleOnScroll}
              leftArrow={leftArrow}
              rightArrow={rightArrow}
              category={this.myRef}
              withLink={!dashboard}
              toAllCategories="/"
              chosenTextColor={chosenTextColor}
            />
          )}
        {user &&
        !user.worksLoaded &&
        (user.worksLoading || user.worksCategoriesLoading) ? (
          <BasicLoader />
        ) : (
          <Fragment>
            {works && works.length > 0 && (
              <WorkGrid
                dashboard={dashboard}
                chosenTextColor={chosenTextColor}
                canMove={!selectedCategory}
                userScreenName={userScreenName}
                data={filteredWorks}
                hasMore={hasMore}
                loadMore={loadMore}
                worksCategories={worksCategories}
                hasPrivateWorks={hasPrivateWorks}
                onDeleteComplete={onDeleteComplete}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withResponsive(connect()(Works));
