import React from 'react';
import styled from 'styled-components';

export const TextInputWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  flex-shrink: 0;
  height: 30px;
  flex-basis: 30px;
  background-color: white;
`;

export const FillTextInput = styled.input`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0 10px;
  outline: none;
  color: ${({ textColor }) => textColor || 'black'};
  border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

const StyledTextInput = ({
  className,
  style,
  textInputStyle,
  textColor,
  icon,
  ...props
}) => (
  <TextInputWrap className={className} style={style}>
    {icon}
    <FillTextInput textColor={textColor} style={textInputStyle} {...props} />
  </TextInputWrap>
);

export default StyledTextInput;
