let extraPatternForDevEnv = '';
if (process.env.NODE_ENV === 'development') {
  extraPatternForDevEnv = '|localhost|foriio.test';
}
const regexForiioDomain = new RegExp(
  `(\\.(foriio|willthismakecreatorhappy)\\.com${extraPatternForDevEnv})`,
  'i',
);
const validateForiioDomain = url => regexForiioDomain.test(url);

export default validateForiioDomain;
