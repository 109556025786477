import UserPage from 'components/UserPage/UserPage';
import WorkPage from 'components/WorkPage/WorkPage';
import NoteView from 'components/WorkPage/NoteView';
import PrivateProtectedPage from 'components/PrivateProtectedPage/PrivateProtectedPage';
import Error404 from 'components/ErrorPage/Error404';

const routes = [
  {
    path: '/works/:work_id',
    component: WorkPage,
    wide: true,
    routes: [
      {
        path: '/works/:work_id/notes/:note_id',
        component: NoteView,
        exact: true,
      },
    ],
  },
  {
    path: '/private',
    component: PrivateProtectedPage,
    wide: true,
  },
  {
    path: '/',
    component: UserPage,
  },
  {
    component: Error404,
  },
];

export default routes;
