import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import FitImage from 'components/FitImage';
import BasicLoader from 'components/BasicLoader';
import { media, mediaMin } from 'util/Responsive';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SizingWrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ zoomable }) =>
    zoomable &&
    css`
      cursor: zoom-in;
    `}
    ${mediaMin.tablet`
      ${({ fitHeight }) =>
        fitHeight
          ? css`
              ${mediaMin.tablet`
                height: 100%;
              `}
            `
          : css`
              padding-bottom: 40%;
              ${({ loaded, ratio }) =>
                loaded &&
                css`
                  padding-bottom: ${ratio * 100}%;
                `};
            `}
    `}
    ${media.tablet`
      padding-bottom: 40%;
      ${({ loaded, ratio }) =>
        loaded &&
        css`
          padding-bottom: ${ratio * 100}%;
        `};
    `}
`;

const PositionContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: #c0c0c0;
`;

const StyledBasicLoader = styled(BasicLoader)`
  height: auto;
`;

const AspectImage = ({
  src,
  alt,
  title,
  srcset,
  zoomable,
  fitHeight,
  imageStyle,
}) => {
  const [loaded, toggleLoaded] = useState(false);
  const [ratio, setRatio] = useState(0.4);
  const imageRef = useRef();

  const transitionStyle = useSpring({
    config: { duration: 300 },
    opacity: loaded ? 1 : 0,
  });

  const handleOnLoadNewImage = () => {
    const { naturalWidth, naturalHeight } = imageRef.current;
    if (!loaded) {
      const imageRatio = naturalHeight / naturalWidth;
      setRatio(imageRatio);
      toggleLoaded(true);
    }
  };

  useEffect(() => {
    if (imageRef.current?.complete) {
      handleOnLoadNewImage();
    }
  }, []);

  return (
    <Wrapper loaded={loaded}>
      <SizingWrapper
        loaded={loaded}
        ratio={ratio}
        fitHeight={fitHeight}
        zoomable={zoomable}
      >
        {!loaded && (
          <LoaderContainer>
            <StyledBasicLoader innerContainerStyle={{ margin: '0 auto' }} />
          </LoaderContainer>
        )}

        <animated.div style={transitionStyle}>
          <FitImage
            customContainer={({ children }) => (
              <PositionContainer>{children}</PositionContainer>
            )}
            src={src}
            srcset={srcset}
            alt={alt}
            title={title}
            ref={imageRef}
            onLoad={handleOnLoadNewImage}
            onError={() => toggleLoaded(true)}
            style={imageStyle}
          />
        </animated.div>
      </SizingWrapper>
    </Wrapper>
  );
};

export default AspectImage;
