/* eslint-disable no-underscore-dangle, no-undef */

import { createStore, compose } from 'redux';

import rootReducer from 'services/redux/reducers';

export default initialState => {
  // eslint-disable-next-line
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()) ||
    compose;
  const store = createStore(rootReducer, initialState, composeEnhancers);
  return store;
};
