import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Header from 'components/Header';
import { BlueButton } from 'components/Buttons';
import CircleAvatar from 'components/CircleAvatar';
import ForiioTextInput from 'components/Form/ForiioTextInput';
import { getCookies } from 'util/cookies';
import { translate } from 'util/Translator';
import { media, mediaMin } from 'util/Responsive';
import { submitPrivateUserSession } from 'services/api';
import {
  USER_PROFILE_CLEAR,
  SINGLE_WORK_CLEAR,
} from 'services/constants/actionTypes';

const Container = styled.div`
  margin: 50px 30px;
`;

const ContentContainer = styled.div`
  ${mediaMin.laptop`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
  `}
`;

const AvatarAndNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 650px;
  ${media.tablet`
    min-width: auto;
    flex-direction: column;
  `}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
  ${media.tablet`
    margin-left: 0;
    margin-top: 30px;
  `}
`;

const Name = styled.div`
  font-size: 38px;
  line-height: 38px;
  font-weight: bold;
  color: #222222;
  margin-bottom: 10px;
  word-break: break-word;
`;

const PasswordForm = styled.form`
  display: flex;
  flex-direction: row;
  ${media.tablet`
    flex-direction: column;
  `}
`;

const PasswordInputContaimer = styled.div`
  width: 100%;
`;

const PasswordInput = styled(ForiioTextInput)`
  height: 35px;
  flex-basis: 35px;
`;

const ErrorMessage = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #eb5757;
  margin-top: 5px;
`;

const SubmitButton = styled(BlueButton)`
  height: 35px;
  ${mediaMin.laptop`
    margin-left: 10px;
  `}
  ${media.tablet`
    margin-top: 15px;
    align-self: center;
  `}
`;

class PrivateProtectedPage extends Component {
  static getInitialData(ctx) {
    const { match } = ctx;
    return {
      match,
    };
  }

  constructor(props) {
    super(props);
    this.cookies = getCookies();
    this.state = {
      password: '',
      error: null,
    };
  }

  componentDidMount() {
    const { location, history, dispatch } = this.props;
    const locationState = this.cookies.get('locationState');
    // handle on refresh current page
    const { from } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (!locationState && location.search) {
      if (from) {
        return history.push(from);
      }
    }
    if (locationState && locationState.fromMatch) {
      // workaround
      const { fromMatch } = locationState;
      if (fromMatch.path === '/') {
        dispatch({
          type: USER_PROFILE_CLEAR,
        });
      } else if (fromMatch.path === '/works/:work_id') {
        dispatch({
          type: SINGLE_WORK_CLEAR,
          workID: fromMatch.params.work_id,
        });
      }
    }
    return null;
  }

  handleOnChangePassword = e => {
    this.setState({
      password: e.target.value,
    });
  };

  handleOnSubmit = e => {
    const { dispatch, history } = this.props;
    e.preventDefault();
    const locationState = this.cookies.get('locationState');
    const { from, user } = locationState;
    const { password } = this.state;
    submitPrivateUserSession(dispatch, user.screen_name, password)
      .then(token => {
        this.cookies.set('p-token', token);
        this.cookies.remove('locationState');
        history.push(from.pathname);
      })
      .catch(({ error }) => {
        this.setState({
          error,
        });
      });
  };

  render() {
    const { t, staticContext } = this.props;
    const { password, error } = this.state;
    const locationState = this.cookies.get('locationState');
    const user = locationState && locationState.user;
    if (staticContext) {
      // eslint-disable-next-line no-param-reassign
      staticContext.statusCode = 401;
    }
    if (!user) {
      return null;
    }
    return (
      <div>
        <Header title={user && user.name} />
        <Container>
          <Helmet>
            {user.avatar && user.avatar.thumb2x && (
              <link rel="shortcut icon" href={user.avatar.thumb} />
            )}
          </Helmet>
          <ContentContainer>
            <AvatarAndNameContainer>
              <CircleAvatar image={user.avatar.thumb2x} size={150} />
              <InfoContainer>
                <Name>{user.name}</Name>
                <PasswordForm onSubmit={this.handleOnSubmit}>
                  <PasswordInputContaimer>
                    <PasswordInput
                      type="password"
                      value={password}
                      placeholder={t('password')}
                      onChange={this.handleOnChangePassword}
                    />
                    {error && (
                      <ErrorMessage>{t('validationMessage')}</ErrorMessage>
                    )}
                  </PasswordInputContaimer>
                  <SubmitButton text={t('enter')} type="submit" />
                </PasswordForm>
              </InfoContainer>
            </AvatarAndNameContainer>
          </ContentContainer>
        </Container>
      </div>
    );
  }
}

export default translate('privateProtected')(
  connect(null)(PrivateProtectedPage),
);
