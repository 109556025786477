import React, { Component } from 'react';
import styled from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import CircleAvatar from 'components/CircleAvatar';
import Divider from 'components/Divider';
import { withResponsive, deviceSizes, media } from 'util/Responsive';
import { translate } from 'util/Translator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  width: 100%;
`;

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    justify-content:  ${({ creditCount }) =>
      creditCount > 1 ? 'flex-start' : 'center'};
    white-space: ${({ creditCount }) => creditCount === 1 && 'pre-wrap'};
 `}
`;

const WorkCreditsTopDivider = styled(Divider)`
  display: none;
  background-color: #f2f2f2;
  ${media.tablet`
    display: block;
    margin-top: 20px;
  `}
`;

const WorkCreditsBottomDivider = styled(Divider)`
  display: none;
  background-color: #f2f2f2;
  ${media.tablet`
    display: block;
    margin: 0  0 20px;
 `}
`;

const StyledCircleAvatar = styled(CircleAvatar)`
  margin-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 18px;
  min-width: 90px;
  ${media.tablet`
    display: none;
 `}
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #222222;
  margin-left: 5px;
  letter-spacing: 0.03em;
`;

const CreditsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const CreditsContainerMobile = styled.div`
  width: 100%;
`;

const Credit = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  height: auto;
  ${media.tablet`
    margin: 20px;
    height: auto;
 `}
`;

const Role = styled.div`
  display: flex;
  flex-direction: row;
`;

const RoleText = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #333132;
  background-color: #dddddd;
  margin: 3px;
  padding: 4px 10px;
  width: fit-content;
  border-radius: 5px;
`;

const UserName = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  margin-bottom: 2px;
  color: #222222;
  text-align: left;
  margin-bottom: 2px;
`;

const RolesSection = styled.div`
  max-width: 250px;
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

class WorkCredits extends Component {
  renderCredits = () => {
    const { credits } = this.props;
    return (
      <HorizontalScrollContainer creditCount={credits.length}>
        {credits.map(credit => (
          <Credit key={credit.user.id} creditCount={credit.length}>
            <div>
              <StyledCircleAvatar image={credit.user.avatar.thumb} size={40} />
            </div>
            <Row style={{ flexDirection: 'column' }}>
              <Row>
                <UserName>{credit.user.name}</UserName>
              </Row>
              <RolesSection>
                {credit.creative_roles.map(role => (
                  <Role key={role.id}>
                    <RoleText>{role.name}</RoleText>
                  </Role>
                ))}
              </RolesSection>
            </Row>
          </Credit>
        ))}
      </HorizontalScrollContainer>
    );
  };

  render() {
    const { appWidth, t } = this.props;
    return (
      <Container>
        <WorkCreditsTopDivider />
        <SubContainer>
          <TitleContainer>
            <Title>{t('credit')}</Title>
          </TitleContainer>
          {appWidth > deviceSizes.tablet ? (
            <CreditsContainer>{this.renderCredits()}</CreditsContainer>
          ) : (
            <CreditsContainerMobile>
              {this.renderCredits()}
            </CreditsContainerMobile>
          )}
        </SubContainer>
        <WorkCreditsBottomDivider />
      </Container>
    );
  }
}

export default translate('workPage')(withResponsive(WorkCredits));
