import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ShareProfileDropdownButton from 'components/UserPage/ShareProfileDropdownButton';
import { toast } from 'react-toastify';
import { translate } from 'util/Translator';
import { trackEvent } from 'util/analytics';
import { getBaseUrl } from 'util/GetBaseUrl';
import { media } from 'util/Responsive';

const animationStart = 280;
const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  z-index: 100;
  background-color: transparent;
  position: sticky;
  top: 10px;
  margin-top: 10px;

  ${media.tablet`
    width: calc(100vw - 40px);
    position: fixed;
    top: 10px;
  `}
`;

const NavigationContainer = styled.div`
  max-width: 1092px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const HeaderProfileOrWorkTitleContainer = styled.div`
  backdrop-filter: blur(10px);
  flex: 1;
  flex-direction: column;
  padding: 9px;
  border-radius: 35px;
  background-color: rgba(255, 255, 255, 0.3);

  border: ${({ chosenTextColor, rgbValues }) =>
    chosenTextColor === 'black'
      ? `solid 1px rgba(${rgbValues?.r + 20}, ${rgbValues?.g +
          20}, ${rgbValues?.b + 20}, 0.3)`
      : `solid 1px rgba(${rgbValues?.r - 20}, ${rgbValues?.g -
          20}, ${rgbValues?.b - 20}, 0.3)`};

  transition: all 0.5s ease-in-out;

  ${media.tablet`
    padding: 9px 0;
    background-color: ${({ scrollPosition }) =>
      scrollPosition > animationStart
        ? 'rgba(255, 255, 255, 0.4)'
        : 'rgba(255, 255, 255, 0)'};

    backdrop-filter: ${({ scrollPosition }) =>
      scrollPosition > animationStart ? 'blur(5px)' : 'blur(0px)'};
      
    border: ${({ scrollPosition, chosenTextColor }) => {
      if (scrollPosition > animationStart) {
        return chosenTextColor === 'black'
          ? 'solid 1px rgba( 0, 0, 0, 0.3)'
          : 'solid 1px rgba( 255, 255, 255, 0.3)';
      }

      return 'solid transparent 1px;';
    }}
  `}
`;

const HeaderProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  border-radius: 25px;
  padding: 0 2px;

  ${media.tablet`
    padding: 0 9px;
  `}
`;

const HeaderProfileInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;

  ${media.tablet`
    opacity: ${({ scrollPosition }) =>
      scrollPosition > animationStart ? '100%' : '0%'};
    display: ${({ scrollPosition }) =>
      scrollPosition > animationStart ? 'flex' : 'none'};
  `}
`;

const HeaderProfileImage = styled.div`
  ${({ image }) =>
    css`
      background-image: url(${image});
    `}

  background-size: cover;
  background-position: center top;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const HeaderProfileName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 7px;

  color: ${({ chosenTextColor }) => {
    if (chosenTextColor === 'white') {
      return 'white';
    }

    return '#222222';
  }};

  ${media.tablet`
    display: none;
  `};
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet`
    margin-left: auto;
  `};
`;

const GlassNavigationHeader = ({ t, user, isSuspended }) => {
  // const dispatch = useDispatch();
  // const locale = useSelector(state => state.locale);
  // const auth = useSelector(state => state.auth);
  const profileUrl = `${getBaseUrl()}`;
  const [scrollPosition, setScrollPosition] = useState(0);

  const throttle = (fn, delay) => {
    let time = Date.now();
    return () => {
      if (time + delay - Date.now() <= 0) {
        fn();
        time = Date.now();
      }
    };
  };

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  // const isCurrentUser = auth?.user?.id === user?.data?.user?.id;

  const onCopyTracking = () => {
    trackEvent({
      action: 'Clicked to copy user profile url',
      label: profileUrl,
    });
    toast('Copied URL');
  };

  useEffect(() => {
    handleScroll();
    const throttledScrollHandler = throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledScrollHandler);

    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
    };
  }, []);

  const handleOnClickUserAvatar = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <NavigationContainer>
        <HeaderProfileOrWorkTitleContainer
          scrollPosition={scrollPosition}
          chosenTextColor={user?.chosenTextColor}
          rgbValues={user?.rgbValues}
        >
          <HeaderProfileContainer>
            {isSuspended ? (
              <>
                <HeaderProfileImage
                  image={user?.data?.avatar?.thumb}
                  onClick={handleOnClickUserAvatar}
                />
                <HeaderProfileName chosenTextColor={user?.chosenTextColor}>
                  {user?.data?.name}
                </HeaderProfileName>
              </>
            ) : (
              <>
                <HeaderProfileInnerContainer scrollPosition={scrollPosition}>
                  <div style={{ position: 'relative' }}>
                    <HeaderProfileImage
                      image={user?.data?.avatar?.thumb}
                      onClick={handleOnClickUserAvatar}
                    />
                  </div>
                  <Link to={`/${user?.data?.user?.screen_name}`}>
                    <HeaderProfileName chosenTextColor={user?.chosenTextColor}>
                      {user?.data?.name}
                    </HeaderProfileName>
                  </Link>
                </HeaderProfileInnerContainer>

                <RightContainer>
                  <CopyToClipboard
                    text={profileUrl.replace('www.foriio.com', 'fori.io')}
                  >
                    <ShareProfileDropdownButton
                      userPageUser={user}
                      scrollPosition={scrollPosition}
                      rgbValues={user?.rgbValues}
                      chosenTextColor={user?.chosenTextColor}
                      profileUrl={profileUrl.replace(
                        'www.foriio.com',
                        'fori.io',
                      )}
                      noMarginRight
                      isInGlassyHeader
                      circleButton
                    />
                  </CopyToClipboard>
                </RightContainer>
              </>
            )}
          </HeaderProfileContainer>
        </HeaderProfileOrWorkTitleContainer>
      </NavigationContainer>
    </Container>
  );
};

export default translate('userPage')(GlassNavigationHeader);
