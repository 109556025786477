import index from './index.json';
import modalForm from './modalForm.json';
import notes from './notes.json';
import workPage from './workPage.json';
import dashboard from './dashboard.json';
import userPage from './userPage.json';
import notifications from './notifications.json';
import privateProtected from './privateProtected.json';
import error from './error.json';

export default {
  index,
  modalForm,
  notes,
  workPage,
  dashboard,
  userPage,
  notifications,
  privateProtected,
  error,
};
