import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { media, mediaMin, withResponsive } from 'util/Responsive';
import FitImage from 'components/FitImage';
import { translate } from 'util/Translator';
import truncateString from 'util/truncateString';
import { trackEvent } from 'util/analytics';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 5px;
  margin-bottom: ${({ horizontal }) => (!horizontal ? '45px' : 0)};
  
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  ${media.tablet`
    width: 33.33%
    flex-basis: 33.33%;
    margin-bottom: ${({ horizontal }) => (!horizontal ? '20px' : 0)};
  `}
  ${media.phone`
    ${({ horizontal }) =>
      horizontal
        ? css`
            width: calc(100vw - 100px);
            flex-basis: calc(100vw - 100px);
            padding-right: 16px;
          `
        : css`
            width: 50%;
            flex-basis: 50%;
          `}
  `}
`;

const WorkInner = styled.div`
  width: 100%;
`;

const WorkSubInner = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  ${media.tablet`
    padding-left: 8px;
    padding-right: 8px;
  `}
`;

const WorkVisual = styled.div`
  padding-bottom: 62.5%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.17);
  transition: all 0.3s;
  ${mediaMin.tablet`
    ${Container}:hover & {
      transform: translateY(-5px);
    }
  `}
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 10px;
  `}
`;

const WorkImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
`;

const WorkTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WorkTitle = styled.div`
  text-decoration: none;
  color: ${({ isWhiteWorkTitle }) =>
    isWhiteWorkTitle ? '#FBFBFB' : '#222222'};
  line-height: 19px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.02em;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  ${media.tablet`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  `}
`;

const NoteBadge = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  top: 14px;
  left: 14px;
  z-index: 20;
  ${media.tablet`
    top: 8px;
    left: 8px;
  `}
`;

const NoteIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6fcf97;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;

const NoteBadgeText = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.47);
`;

const WorkControls = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  ${mediaMin.tablet`
    ${Container}:hover & {
      display: flex;
    }
  `}
`;

const ControlText = styled.div`
  color: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  letter-spacing: 0.02em;
  white-space: nowrap;
`;

const CircleIconControlText = styled(ControlText)`
  position: absolute;
  margin-left: 0;
  margin-top: 40px;
  font-size: 13px;
  visibility: hidden;
`;

const CircleIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 30px;
  width: 30px;
  overflow: hidden;
  background-color: #fbfbfb;
`;

const ViewAndLockContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ViewControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ dashboard }) =>
    !dashboard &&
    css`
      flex-direction: row;
    `}
  ${mediaMin.tablet`
    :hover {
      ${CircleIconContainer} {
        background-color: #BDBDBD;
      }
      ${CircleIconControlText} {
        visibility: visible;
      }
    }
  `}
`;

const RoleControl = styled.div`
  position: absolute;
  bottom: 15px;
  right: 0;
  width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
`;

const RoleSubControl = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: flex-end;
  margin: 0 15px;
`;

const RoleNameContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const RoleText = styled(ControlText)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MoreRolesText = styled(ControlText)`
  width: auto;
  overflow: initial;
`;

const WorkLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: auto;
  content: '';
`;

const WorkTypeIndicatorContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const WorkTypeIndicatorSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WorkTypeText = styled(ControlText)`
  margin-right: 7px;
`;

const WorkTypeCircleIconContainer = styled(
  ({ backgroundColor, ...otherProps }) => <div {...otherProps} />,
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const WorkTypeIndicator = ({ text, icon, backgroundColor, iconColor }) => (
  <WorkTypeIndicatorContainer>
    <WorkTypeIndicatorSubContainer>
      <WorkTypeText>{text}</WorkTypeText>
      <WorkTypeCircleIconContainer backgroundColor={backgroundColor}>
        <FontAwesomeIcon icon={['fas', icon]} color={iconColor} size={10} />
      </WorkTypeCircleIconContainer>
    </WorkTypeIndicatorSubContainer>
  </WorkTypeIndicatorContainer>
);

const handleOnClickSingleWork = id => {
  trackEvent({
    category: 'click:single-work-view',
    action: `User clicked on a work to view`,
    label: `id: ${id}`,
  });
};

class SingleWork extends Component {
  getWorkTypeIndicatorParameters = type => {
    const { t } = this.props;
    switch (type) {
      case 'video':
        return {
          icon: 'video',
          text: t('video'),
          backgroundColor: '#FFCCCC',
          iconColor: '#E05C5C',
        };
      case 'sound':
        return {
          icon: 'volume-up',
          text: t('sound'),
          backgroundColor: '#FFC38D',
          iconColor: '#FF7800',
        };
      case 'web_article':
        return {
          icon: 'globe',
          text: t('webArticle'),
          backgroundColor: '#CCE1FF',
          iconColor: '#3485FF',
        };
      case 'embedded3d':
        return {
          icon: 'cube',
          text: t('threeDModel'),
          backgroundColor: '#BB55FE',
          iconColor: '#ddaafe',
        };
      case 'image':
      default:
        return {
          icon: 'image',
          text: t('image'),
          backgroundColor: '#AFF2D8',
          iconColor: '#00C396',
        };
    }
  };

  render() {
    const {
      index,
      work,
      horizontal,
      appWidth,
      t,
      isWhiteWorkTitle,
      chosenTextColor,
      style,
    } = this.props;

    return (
      <Container
        ref={ref => {
          this.containerRef = ref;
        }}
        appWidth={appWidth}
        horizontal={horizontal}
        disabled={work.isUpdatingVisibility}
        style={style}
        index={index}
      >
        <Link
          to={{
            pathname: `/works/${work.id}`,
            search: work.secret ? `?secret=${work.secret}` : null,
          }}
          onClick={() => handleOnClickSingleWork(work.id)}
        >
          <WorkInner>
            <WorkSubInner>
              <WorkVisual>
                <FitImage
                  customContainer={WorkImageContainer}
                  src={work.thumbnail}
                  srcset={work.srcset}
                  alt={work.title}
                />
                {!!work.notes_count && (
                  <NoteBadge>
                    <NoteIconWrap>
                      <FontAwesomeIcon
                        icon={['fas', 'sticky-note']}
                        color="#FBFBFB"
                        size={10}
                      />
                    </NoteIconWrap>
                    <NoteBadgeText>
                      {work.notes_count === 1
                        ? t('noteCount', { number: work.notes_count })
                        : t('notesCount', { number: work.notes_count })}
                    </NoteBadgeText>
                  </NoteBadge>
                )}
                <WorkControls>
                  {!!(work.user_roles && work.user_roles.length) && (
                    <RoleControl>
                      <RoleSubControl>
                        <RoleNameContainer>
                          <FontAwesomeIcon
                            icon={['fas', 'user-tag']}
                            color="#F2F2F2"
                            size={14}
                          />
                          <RoleText>{work.user_roles[0]}</RoleText>
                        </RoleNameContainer>
                        {work.user_roles.length > 1 && (
                          <MoreRolesText>
                            {t('workCardMoreUserRoles', {
                              number: work.user_roles.length - 1,
                            })}
                          </MoreRolesText>
                        )}
                      </RoleSubControl>
                    </RoleControl>
                  )}
                  <WorkTypeIndicator
                    {...this.getWorkTypeIndicatorParameters(work.type)}
                  />
                  <ViewAndLockContainer>
                    <ViewControl>
                      <FontAwesomeIcon
                        icon={['fas', 'eye']}
                        color="#F2F2F2"
                        size={14}
                      />
                      <ControlText>{t('workCardView')}</ControlText>
                    </ViewControl>
                  </ViewAndLockContainer>
                </WorkControls>
              </WorkVisual>
              <WorkTitleContainer>
                <WorkTitle
                  isWhiteWorkTitle={
                    isWhiteWorkTitle || chosenTextColor === 'white'
                  }
                >
                  {truncateString(work.title, 30)}
                </WorkTitle>
              </WorkTitleContainer>
            </WorkSubInner>
          </WorkInner>
        </Link>
      </Container>
    );
  }
}

export default translate('dashboard')(
  withResponsive(connect()(withRouter(SingleWork))),
);
