import React, { Fragment } from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const renderSoundWork = sound => {
  switch (sound.platform) {
    case 'soundcloud':
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: sound.metadata.html }} />;
    case 'mixcloud':
      return (
        <iframe
          title={sound.title}
          width="100%"
          height={400}
          src={`https://www.mixcloud.com/widget/iframe/?light=1&feed=/${
            sound.metadata.track_id
          }`}
          frameBorder="0"
        />
      );
    case 'bandcamp':
      return (
        <iframe
          title={sound.title}
          style={{ border: 0, alignSelf: 'center', maxWidth: 350 }}
          width="100%"
          height={470}
          src={`${sound.metadata['og:video'].replace(
            'artwork=small/',
            '',
          )}transparent=true`}
          seamless
        >
          <a href={sound.url}>{sound.title}</a>
        </iframe>
      );
    default:
      return null;
  }
};
const SoundWorks = pure(({ sounds }) => (
  <Fragment>
    {sounds.map(sound => (
      <Container key={sound.url}>{renderSoundWork(sound)}</Container>
    ))}
  </Fragment>
));

export default SoundWorks;
