import { combineReducers } from 'redux';
import user from './user';
import works from './works';
import worksCategories from './worksCategories';
import notes from './notes';
import locale from './locale';
import notifications from './notifications';

const rootReducer = combineReducers({
  user,
  works,
  worksCategories,
  notes,
  locale,
  notifications,
});

export default rootReducer;
