import React from 'react';
import styled from 'styled-components';
import Divider from 'components/Divider';
import WorkNoteList from 'components/WorkPage/WorkNoteList';
import { translate } from 'util/Translator';
import { media } from 'util/Responsive';

const Container = styled.div``;

const TopDivider = styled(Divider)`
  border-color: #f2f2f2;
  margin-bottom: 20px;
`;

const StyledWorkNoteList = styled(WorkNoteList)`
  margin: 0;
`;

const SubContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 18px;
  min-width: 90px;
  ${media.tablet`
    display: none;
 `}
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #222222;
  margin-left: 5px;
  letter-spacing: 0.03em;
`;

const ListShowMoreTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WorkInfoNoteList = props => {
  const { t, work, notes, onClickViewNote } = props;
  return (
    <Container>
      <TopDivider />
      <SubContainer>
        <TitleContainer>
          <Title>{t('notes')}</Title>
        </TitleContainer>
        <ListShowMoreTextContainer>
          <StyledWorkNoteList
            notes={notes}
            work={work}
            onClickViewNote={onClickViewNote}
          />
        </ListShowMoreTextContainer>
      </SubContainer>
    </Container>
  );
};

export default translate('notes')(WorkInfoNoteList);
