import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BlueButton } from 'components/Buttons';
import { translate } from 'util/Translator';
import { getForiioBaseUrl } from 'util/GetBaseUrl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const Title = styled.div`
  font-size: 45px;
  font-weight: bold;
  line-height: 45px;
  text-align: center;
  color: #333333;
  margin-bottom: 11px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #828282;
  margin-bottom: 48px;
`;

const BaseError = ({ t, title, description, children, staticContext }) => {
  const { url, isExternalUrl } = getForiioBaseUrl(staticContext);
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
      {isExternalUrl ? (
        <a href={url} style={{ marginTop: 30 }}>
          <BlueButton fullyRound text={t('goHome')} />
        </a>
      ) : (
        <Link to="/" style={{ marginTop: 30 }}>
          <BlueButton fullyRound text={t('goHome')} />
        </Link>
      )}
    </Container>
  );
};

export default translate('error')(BaseError);
