import {
  NEW_NOTIFICATION,
  DESTROY_NOTIFICATION,
} from 'services/constants/actionTypes';

import { v4 as uuid } from 'uuid';

export const destroyNotification = (dispatch, id) => {
  dispatch({ type: DESTROY_NOTIFICATION, id });
};

export const launchNotification = (dispatch, data) => {
  const newID = uuid();

  dispatch({ type: NEW_NOTIFICATION, data: { ...data, id: newID } });
  setTimeout(() => {
    destroyNotification(dispatch, newID);
  }, 3000);
};
