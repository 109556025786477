import React, { useEffect } from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import BasicLoader from 'components/BasicLoader';
import CircleAvatar from 'components/CircleAvatar';
import ProfileSNSLinks from 'components/UserPage/profileSnsLinks';
import { translate } from 'util/Translator';
import truncateString from 'util/truncateString';
import { deviceSizes } from 'util/Responsive';
import { pageview, trackEvent } from 'util/analytics';

const Container = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: -270px;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 660px;
  background-image: ${({ image }) => `url(${image})`};
  filter: blur(
    ${({ isAvatar, isMobile }) => (isAvatar && !isMobile ? '100px' : '0.5px')}
  );
  opacity: 0.7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ProfileContentContainer = styled.div`
  display: flex;
  padding-top: ${({ isAvatar }) => (isAvatar ? '100px' : '255px')};
  flex-direction: row;
  max-width: 1090px;
  width: 100%;
`;

const ProfileOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

const ProfileImage = styled.div`
  margin-right: 20px;
`;

const ProfileNameAndSNSContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBioLinkify = styled(Linkify)`
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'white' : '#424242'};

  color: ${({ isDefaultLayout }) => isDefaultLayout && '#33333'};

  display: inline;
`;

const ProfileName = styled.div`
  font-size: 38px;
  font-weight: bold;
  line-height: 38px;
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'white' : '#222222'};
  margin-right: 15px;
  word-break: break-word;
  font-family: 'Noto Sans JP', sans-serif;
  margin: 5px 0;
`;

const ProfileDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfileInfoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 13px;
`;
const ProfileInfo = styled.div`
  font-size: 15px;
  font-style: medium;
  line-height: normal;
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'rgb(221, 221, 221)' : '#828282'};
  letter-spacing: 0.02em;
  white-space: nowrap;
  letter-spacing: 0.02em;
  font-weight: 600;
`;

const BioContainer = styled.div`
  margin-top: 15px;
`;

const Bio = styled.div`
  font-family: 'Noto Sans JP';
  line-height: 23px;
  font-size: 13px;
  color: #333333;
  white-space: pre-wrap;
`;

const ShowMoreButton = styled.button`
  display: inline;
  cursor: pointer;
  background-color: transparent;
  color: #5863f8;
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  padding: 0;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const BackgroundImageGradient = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  width: 100%;
  height: 100%;
  transform: translateY(1px);
  background: ${({ rgbValues }) =>
    `linear-gradient(
    180deg,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 0) 40%,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 1) 85%,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 1) 100%
  );`};
  pointer-events: none;
`;

const Profile = ({
  appWidth,
  location,
  loading,
  data,
  user,
  t,
  onPressOpenProfileModal,
}) => {
  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    };
    handleRouteChange(location.pathname);
  }, [location.pathname]);

  const handleOnClickShowMore = () => {
    onPressOpenProfileModal();
    trackEvent({
      category: 'click:show-more-button',
      action: 'User show more in profile',
    });
  };

  const SNSLinks = [
    ['twitter', data?.twitter_url],
    ['youtube', data?.youtube_url],
    ['instagram', data?.instagram_url],
    ['facebook', data?.facebook_url],
    ['website', data?.website],
  ].filter(link => link[1]);

  const getCurrentHeaderImage = () => {
    if (user?.data?.header_data?.header_image_type === 'work') {
      return user?.data?.header_data?.header_work_image;
    }

    return (
      user?.data?.header_data?.header_profile_image || user?.data?.avatar?.phone
    );
  };

  const isMobile = appWidth < deviceSizes.tablet;
  const chosenTextColor = user?.chosenTextColor;
  const rgbValues = user?.rgbValues;
  const isAvatarBasedHeader =
    user?.data?.header_data?.header_image_type !== 'work';

  return (
    <Container>
      {loading ? (
        <BasicLoader />
      ) : (
        <div>
          <BackgroundImage
            image={
              getCurrentHeaderImage() ||
              'https://dyci7co52mbcc.cloudfront.net/static/images/default_avatar.jpg'
            }
            isAvatar={isAvatarBasedHeader}
            isMobile={isMobile}
          >
            <BackgroundImageGradient
              rgbValues={rgbValues}
              isAvatar={isAvatarBasedHeader}
            />
          </BackgroundImage>
          <ProfileContainer>
            <ProfileOverlayContainer />
            <ProfileContentContainer isAvatar={isAvatarBasedHeader}>
              <ProfileImage>
                <CircleAvatar
                  image={data && data.avatar && data.avatar.thumb}
                  size={110}
                />
              </ProfileImage>
              <RightContainer>
                <ProfileNameAndSNSContainer>
                  {!!data.profession && (
                    <ProfileInfoItemContainer>
                      <ProfileInfo>{data.profession}</ProfileInfo>
                    </ProfileInfoItemContainer>
                  )}
                  <ProfileName chosenTextColor={chosenTextColor}>
                    {data.name}
                  </ProfileName>
                </ProfileNameAndSNSContainer>
                <ProfileDetailsContainer>
                  <ProfileInfoContainer>
                    {!!data.location && (
                      <ProfileInfoItemContainer>
                        <ProfileInfo chosenTextColor={chosenTextColor}>
                          {data.location}
                        </ProfileInfo>
                      </ProfileInfoItemContainer>
                    )}
                  </ProfileInfoContainer>
                </ProfileDetailsContainer>
                <ProfileSNSLinks
                  t={t}
                  userData={data}
                  chosenTextColor={user?.chosenTextColor}
                  // isDefaultLayout={isDefaultLayout}
                  // isCurrentUser={isCurrentUser}
                  SNSLinks={SNSLinks}
                />
                {!!(data.bio && data.bio.length > 0) && (
                  <BioContainer>
                    <Bio>
                      <StyledBioLinkify
                        chosenTextColor={chosenTextColor}
                        properties={{ target: '_blank' }}
                      >
                        {truncateString(
                          data.bio.split('\n', 3).join('\n'),
                          100,
                        )}
                      </StyledBioLinkify>

                      {!!(data.bio && data.bio.length > 50) && (
                        <ShowMoreButton
                          onClick={handleOnClickShowMore}
                          chosenTextColor={chosenTextColor}
                        >
                          {t('showMore')}
                        </ShowMoreButton>
                      )}
                    </Bio>
                  </BioContainer>
                )}
              </RightContainer>
            </ProfileContentContainer>
          </ProfileContainer>
        </div>
      )}
    </Container>
  );
};

export default withRouter(translate('userPage')(Profile));
