export const GA_MEASUREMENT_ID = process.env.RAZZLE_PUBLIC_GOOGLE_TRACKING_CODE;
// https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag

export const pageview = url => {
  if (typeof window !== 'undefined') {
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_location: url,
    });
  }
};

export const trackEvent = ({ action, category, label, value }) => {
  if (typeof window !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
