import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import NoteList from 'components/WorkPage/NoteList';
import NoteView from 'components/WorkPage/NoteView';
import { translate } from 'util/Translator';
import { media } from 'util/Responsive';

const NoteBodyWrap = styled.div`
  background: #fff;
  position: relative;
  overflow: auto;
  transition: all 0.7s;
  &.entering,
  &.entered {
    opacity: 1;
    width: ${({ mobile }) => (mobile ? '100%' : '495px')};
  }
  &.exiting,
  &.exited {
    opacity: 1;
    width: 0;
  }
  ${media.tablet`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  `}
`;

const NoteMobileHeader = styled.div`
  position: relative;
  display: none;
  ${media.tablet`
    display: flex;
    height: 60px;
    background-color: #EB5757;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;

const NoteMobileHeaderControl = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  left: 20px;
  color: white;
`;

const NoteMobileHeaderText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-right: 8px;
`;

const NoteClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  ${media.tablet`
    display: none;
  `}
`;

const NoteCloseText = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: #bdbdbd;
`;

class NoteBody extends Component {
  scrollToTop = () => {
    if (this.container) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.container.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      t,
      mobile,
      className,
      work,
      noteMode,
      isVisibleSmartBanner,
    } = this.props;
    return (
      <NoteBodyWrap
        className={className}
        mobile={mobile}
        ref={ref => (this.container = ref)}
      >
        <NoteMobileHeader isVisibleSmartBanner={isVisibleSmartBanner}>
          <NoteMobileHeaderControl>
            {!noteMode && (
              <Link
                to={`/works/${work.data.id}${
                  work.data.secret ? `?secret=${work.data.secret}` : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={['fas', 'times']}
                  color="#fff"
                  size={16}
                />
              </Link>
            )}
            {noteMode && (
              <Link
                to={`/works/${work.data.id}/notes${
                  work.data.secret ? `?secret=${work.data.secret}` : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={['fas', 'chevron-left']}
                  color="#fff"
                  size={16}
                />
              </Link>
            )}
          </NoteMobileHeaderControl>
          <NoteMobileHeaderText>{t('titleNoteHeader')}</NoteMobileHeaderText>
        </NoteMobileHeader>
        <NoteClose>
          <Link
            to={`/works/${work.data.id}${
              work.data.secret ? `?secret=${work.data.secret}` : ''
            }`}
          >
            <NoteCloseText>
              {`${t('close')} `}
              <FontAwesomeIcon icon={['fas', 'times']} color="#fff" size={14} />
            </NoteCloseText>
          </Link>
        </NoteClose>
        <Switch>
          <Route
            path="/works/:work_id/notes/:note_id"
            exact
            render={routeProps => (
              <NoteView onClickViewNote={this.scrollToTop} {...routeProps} />
            )}
          />
          <Route
            path="/works/:work_id/notes"
            exact
            render={routeProps => (
              <NoteList
                workID={work.data.id}
                secret={work.data.secret}
                notes={work.notesList}
                onClickViewNote={this.scrollToTop}
                {...routeProps}
              />
            )}
          />
        </Switch>
      </NoteBodyWrap>
    );
  }
}

export default translate('workPage')(NoteBody);
