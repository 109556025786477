import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Profile from 'components/UserPage/Profile';
import ProfileMobile from 'components/UserPage/ProfileMobile';
import Works from 'components/UserPage/Works';
import AboutModal from 'components/AboutModal';
import BasicLoader from 'components/BasicLoader';
import CircleAvatar from 'components/CircleAvatar';
import GlassNavigationHeader from 'components/GlassNavigationHeader';
import FloatingButton from 'components/FloatingButton';
import errorHandler from 'components/ErrorPage/errorHandler';
import { translate } from 'util/Translator';
import { withResponsive, deviceSizes } from 'util/Responsive';
import { getOGUrl } from 'util/GetBaseUrl';
import {
  getUserProfile,
  getUserWorks,
  getUserWorksCategories,
} from 'services/api';
import { USER_WORKS_CLEAR } from 'services/constants/actionTypes';

const PageContainer = styled.div`
  position: relative;
  background: ${({ hexBackgroundColor }) => hexBackgroundColor};
  margin-top: 30px;
`;

const ContactUserFloatingButton = styled(FloatingButton)`
  background-color: #6fcf97;
  bottom: 70px;
  :hover {
    background-color: rgb(102, 194, 140);
  }
  :active {
    background-color: rgb(91, 177, 126);
  }
`;

const ContactUserFloatingButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 25px 10px 10px;
  align-items: center;
`;

const ContactUserNameContainer = styled.div`
  margin-left: 10px;
  text-align: left;
`;

const ContactUser = styled.div`
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #fbfbfb;
`;

const ContactUserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.03em;
  color: #fbfbfb;
`;

class UserPage extends React.Component {
  static getInitialData(ctx) {
    const { match, dispatch, staticContext, screenName, category } = ctx;
    let cat = null;
    if (staticContext && staticContext.reqUrl) {
      const reqUrlParts = staticContext.reqUrl.split('/');
      if (reqUrlParts.length === 5) {
        if (reqUrlParts[3] === 'categories') {
          // eslint-disable-next-line prefer-destructuring
          cat = reqUrlParts[4];
        }
      }
    }
    return Promise.all([
      getUserProfile(dispatch, screenName),
      getUserWorks(dispatch, screenName, 1, category || cat),
      getUserWorksCategories(dispatch, screenName),
    ]).then(() => {
      let serverAppWidth;
      if (
        staticContext &&
        staticContext.mobileDetect &&
        staticContext.mobileDetect.mobile()
      ) {
        serverAppWidth = staticContext.mobileDetect.maxPhoneWidth;
      } else {
        serverAppWidth = 1280;
      }
      return {
        match,
        serverAppWidth,
        isFetchedFromServer: !!staticContext,
      };
    });
  }

  constructor(props) {
    const { worksMeta } = props;
    super(props);
    this.state = {
      isOpenProfileModal: false,
      selectedCategory: null,
      isInitSelectedCategory: false,
      totalPagesForAllResults:
        worksMeta && worksMeta.total_pages ? worksMeta.total_pages : 1,
      isMounted: false,
    };
  }

  componentDidMount() {
    const {
      screenName,
      dispatch,
      history,
      location,
      match,
      isFetchedFromServer,
      user,
      matchCategoryRoute,
    } = this.props;
    // listen to route change, clear user works if works are filtered by category and filtered by server api
    this.history = history.listen(loc => {
      const { selectedCategory } = this.state;
      if (location !== loc && selectedCategory && loc.pathname !== '/') {
        dispatch({ type: USER_WORKS_CLEAR, userID: screenName });
        this.setState({
          selectedCategory: null,
        });
      }
    });
    // Skip fetching if data fetched from server side
    if (!isFetchedFromServer) {
      this.constructor.getInitialData({
        match,
        dispatch,
        screenName,
        category: matchCategoryRoute
          ? matchCategoryRoute.params.category
          : null,
      });
    } else if (!user.worksLoaded) {
      getUserWorks(
        dispatch,
        screenName,
        1,
        matchCategoryRoute ? matchCategoryRoute.params.category : null,
      );
    }
    this.setState({
      isMounted: true,
    });
  }

  componentDidUpdate() {
    const {
      category,
      worksCategories,
      onChangeCategory,
      user,
      matchCategoryRoute,
    } = this.props;
    const { category: cat, rightArrow, isInitSelectedCategory } = this.state;
    if (this.myRef && this.myRef.current && rightArrow === null) {
      this.setState({
        rightArrow:
          this.myRef.current.clientWidth < this.myRef.current.scrollWidth,
      });
    }
    if (
      user.worksCategoriesLoaded &&
      matchCategoryRoute &&
      matchCategoryRoute.params.category &&
      !isInitSelectedCategory
    ) {
      const selectedCategory = worksCategories.find(
        c => decodeURI(c.slug) === matchCategoryRoute.params.category,
      );
      this.setState({
        selectedCategory,
        isInitSelectedCategory: true,
      });
    }
    if (cat !== category) {
      const selectedCategory =
        worksCategories && worksCategories.length
          ? worksCategories.find(c => decodeURI(c.slug) === category)
          : null;
      this.setState({
        selectedCategory,
        category,
      });
      onChangeCategory(selectedCategory);
    }
  }

  handleOnLoadMore = () => {
    const { dispatch, screenName, worksMeta } = this.props;
    const { selectedCategory } = this.state;
    getUserWorks(
      dispatch,
      screenName,
      worksMeta.current_page + 1,
      selectedCategory ? selectedCategory.slug : null,
    );
  };

  handleOnPressOpenProfileModal = () => {
    this.setState({
      isOpenProfileModal: true,
    });
  };

  handleOnPressCloseProfileModal = () => {
    this.setState({
      isOpenProfileModal: false,
    });
  };

  handleOnChangeCategory = category => {
    this.setState({ selectedCategory: category }, () => {
      const { dispatch, screenName } = this.props;
      getUserWorks(dispatch, screenName, 1, category ? category.slug : null);
    });
  };

  handleOnClickOpenEmail = email => {
    window.location = `mailto:${email}`;
  };

  renderUserWorks = () => {
    const {
      user,
      works,
      worksMeta,
      worksCategories,
      matchCategoryRoute,
    } = this.props;
    const { totalPagesForAllResults } = this.state;
    const chosenTextColor = user?.chosenTextColor;

    return (
      <Works
        works={works}
        chosenTextColor={chosenTextColor}
        totalPages={totalPagesForAllResults}
        hasMore={worksMeta && worksMeta.current_page < worksMeta.total_pages}
        loadMore={this.handleOnLoadMore}
        worksCategories={worksCategories}
        category={
          matchCategoryRoute ? matchCategoryRoute.params.category : null
        }
        onChangeCategory={this.handleOnChangeCategory}
      />
    );
  };

  render() {
    const {
      t,
      appWidth,
      user,
      contentOffsetY,
      serverAppWidth,
      locale,
      staticContext,
    } = this.props;
    const { isOpenProfileModal, isMounted } = this.state;
    const hexBackgroundColor = user?.hexBackgroundColor || 'white';
    return (
      <PageContainer hexBackgroundColor={hexBackgroundColor}>
        <Helmet>
          <title>{user.data.name}</title>
          <meta name="description" content={user.data.bio} />
          <meta property="og:title" content={user.data.name} />
          <meta property="og:description" content={user.data.bio} />
          <meta property="og:url" content={getOGUrl(staticContext)} />
          {user.data.avatar && user.data.avatar.thumb2x && (
            <meta property="og:image" content={user.data.avatar.thumb2x} />
          )}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={user.data.name} />
          <meta name="twitter:text:title" content={user.data.name} />
          <meta name="twitter:description" content={user.data.bio} />
          {user.data.avatar && user.data.avatar.thumb2x && (
            <meta name="twitter:image" content={user.data.avatar.thumb2x} />
          )}
          {user.data.avatar && user.data.avatar.thumb2x && (
            <link rel="shortcut icon" href={user.data.avatar.thumb} />
          )}
        </Helmet>
        <GlassNavigationHeader user={user} />
        {appWidth > deviceSizes.phone ? (
          <Profile
            loading={user.loading}
            user={user}
            data={user.data}
            onPressOpenProfileModal={this.handleOnPressOpenProfileModal}
          />
        ) : (
          <ProfileMobile
            loading={user.loading}
            data={user.data}
            user={user}
            contentOffsetY={contentOffsetY}
            onPressOpenProfileModal={this.handleOnPressOpenProfileModal}
            serverAppWidth={serverAppWidth}
          />
        )}

        <Switch>
          <Route
            path="/"
            exact
            render={routeProps => this.renderUserWorks(routeProps)}
          />
          <Route
            path="/categories/:category"
            exact
            render={routeProps => this.renderUserWorks(routeProps)}
          />
        </Switch>

        {user &&
          !user.worksLoaded &&
          (user.worksLoading || user.worksCategoriesLoading) && <BasicLoader />}
        {user && user.data && (
          <AboutModal
            isVisible={isOpenProfileModal}
            title={t('profile')}
            description={user.data.bio}
            onClose={this.handleOnPressCloseProfileModal}
          />
        )}
        {user &&
          !user.loading &&
          user.data &&
          !!user.data.contact_email &&
          appWidth > deviceSizes.phone &&
          isMounted && (
            <ContactUserFloatingButton
              hideBadge
              onClick={() =>
                this.handleOnClickOpenEmail(user.data.contact_email)
              }
            >
              <ContactUserFloatingButtonContentContainer>
                <CircleAvatar image={user.data.avatar.thumb} size={40} />
                <ContactUserNameContainer>
                  {locale === 'ja' ? (
                    <Fragment>
                      <ContactUserName>
                        {user.data.name.split(' ')[0]}
                      </ContactUserName>
                      <ContactUser>{t('contactUser')}</ContactUser>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <ContactUser>{t('contactUser')}</ContactUser>
                      <ContactUserName>
                        {user.data.name.split(' ')[0]}
                      </ContactUserName>
                    </Fragment>
                  )}
                </ContactUserNameContainer>
              </ContactUserFloatingButtonContentContainer>
            </ContactUserFloatingButton>
          )}
      </PageContainer>
    );
  }
}

export default translate('userPage')(
  connect((state, props) => {
    const { user, works, worksCategories } = state;
    const { match, location } = props;
    const userWorks = user.workIDs
      ? user.workIDs.map(workID => works[workID].data)
      : [];
    const selectedWorksCategories = (
      (user && user.worksCategoriesIDs) ||
      []
    ).map(categoryID => worksCategories[categoryID].data);

    return {
      user,
      works: userWorks,
      worksMeta: user.worksMeta,
      worksCategories: selectedWorksCategories,
      error: user && user.error,
      ...props.initialData,
      match,
      matchCategoryRoute: matchPath(location.pathname, {
        path: '/categories/:category',
        exact: true,
        strict: false,
      }),
    };
  })(withResponsive(errorHandler(UserPage))),
);
