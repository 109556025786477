/* eslint-disable react/no-multi-comp */

import React from 'react';
import { css } from 'styled-components';
import hoistNonReactStatic from 'hoist-non-react-statics';

const { Provider, Consumer } = React.createContext({
  appWidth: typeof document !== 'undefined' ? document.body.clientWidth : 0,
  appHeight: typeof document !== 'undefined' ? document.body.clientHeight : 0,
});

export class ResponsiveProvider extends React.Component {
  constructor(props) {
    super(props);
    let appWidth;
    let appHeight;
    const { staticContext } = props;
    if (staticContext && staticContext.mobileDetect) {
      if (staticContext.mobileDetect.mobile()) {
        appWidth = staticContext.mobileDetect.maxPhoneWidth;
        appHeight = 375;
      } else {
        appWidth = 1280;
        appHeight = 800;
      }
    } else {
      appWidth =
        typeof document !== 'undefined' ? document.body.clientWidth : 0;
      appHeight =
        typeof document !== 'undefined' ? document.body.clientHeight : 0;
    }
    this.state = {
      appWidth,
      appHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      appWidth: document.body.clientWidth,
      appHeight: document.body.clientHeight,
    });
  };

  render() {
    const { children } = this.props;
    const { appWidth, appHeight } = this.state;
    return (
      <Provider
        value={{
          appWidth,
          appHeight,
        }}
      >
        {children}
      </Provider>
    );
  }
}

export const withResponsive = WrappedComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  class Responsive extends React.Component {
    render() {
      return (
        <Consumer>
          {responsive => (
            <WrappedComponent {...{ ...responsive, ...this.props }} />
          )}
        </Consumer>
      );
    }
  }
  hoistNonReactStatic(Responsive, WrappedComponent);
  return Responsive;
};

export const deviceSizes = {
  laptop: 1200,
  tablet: 1000,
  phone: 750,
  tiny: 320,
};

export const media = Object.keys(deviceSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${deviceSizes[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const mediaMin = Object.keys(deviceSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${deviceSizes[label] + 1}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
