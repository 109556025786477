import React from 'react';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { pure } from 'recompose';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import styled, { css } from 'styled-components';
import { media } from 'util/Responsive';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { WhiteButton } from 'components/Buttons';
import ShareButton from 'components/WorkPage/ShareButton';
import { getBaseUrl } from 'util/GetBaseUrl';
import { translate } from 'util/Translator';

const SubContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 20px;
  position: relative;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px, rgba(0, 0, 0, 0.08) 0px 8px 16px;
  padding: 12px 20px;
  margin-top: 12px;

  ${({ fixed }) =>
    fixed &&
    css`
      width: 800px;
      margin: 0 auto;
      padding-top: 25px;
      padding-bottom: 25px;
    `};

  ${media.tablet`
    margin-bottom: 30px;
    box-shadow: none;
    padding: 0;
  `}
`;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  flex: 1;
  box-sizing: border-box;
`;

const ShareText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #222222;
  margin-right: 20px;
  ${media.tiny`
    display: none;
  `}
`;

const StyledShareButton = styled(ShareButton)`
  margin-right: 10px;
`;

const EmbedCodeButton = styled(WhiteButton)`
  padding: 8px 16px;
  color: #4f4f4f;
  font-size: 12px;
  margin-right: 2px;
  margin-left: 2px;
  background-color: white;
  & svg {
    margin-top: -3px;
  }
  ${media.phone`
    padding: 8px 10px;
  `}
  &:hover {
    background-color: #dddddd;
  }
`;

const CopyWorkCodeButton = styled(EmbedCodeButton)`
  margin-left: auto !important;
`;

const getShareUrl = (workID, noteID, secret, staticContext) =>
  noteID
    ? `${getBaseUrl(staticContext)}/works/${workID}/notes/${noteID}${
        secret ? `?secret=${secret}` : ''
      }`
    : `${getBaseUrl(staticContext)}/works/${workID}${
        secret ? `?secret=${secret}` : ''
      }`;

const getFacebookUrl = (work, note, staticContext) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${getShareUrl(
    (work || {}).id,
    (note || {}).id,
    work.secret,
    staticContext,
  )}`;
};

const getTwitterUrl = (work, note, staticContext) => {
  const noteUser = (note || {}).user || { profile: {} };
  const workUser = (work || {}).author || { profile: {} };

  const twitterUrl = (note ? noteUser : workUser).profile.twitter_url || '';
  const twitter = twitterUrl !== '' && twitterUrl.split('/').pop();

  const tweetText = `${note ? `${note.title} - ` : ''}${work && work.title}${
    twitter ? `, by @${twitter}` : ''
  }`;
  const url = `https://www.twitter.com/intent/tweet?url=${getShareUrl(
    (work || {}).id,
    (note || {}).id,
    work.secret,
    staticContext,
  )}&via=foriio_official&text=${encodeURI(tweetText)}`;

  return url;
};

const getPinterestUrl = (work, note, staticContext) => {
  const {
    title,
    images,
    author: {
      profile: { name },
    },
  } = work;
  const mediaImage = `${images && images[0].urls.detail}`;
  const description = `${title} by ${name} - foriio Portfolio platform`;

  const url = `http://pinterest.com/pin/create/link/?url=${getShareUrl(
    (work || {}).id,
    (note || {}).id,
    work.secret,
    staticContext,
  )}&media=${mediaImage}&description=${description}`;

  return url;
};

const ShareWorkOrNote = pure(
  ({
    t,
    user,
    work,
    note,
    canEdit,
    onEdit,
    fixed,
    portalMode,
    onEditThumbnail,
    staticContext,
    ...rest
  }) => {
    const copyProfileUrl = () => {
      const profileUrl = `${getBaseUrl()}`;
      copy(profileUrl);
      toast('Copied URL');
    };

    return (
      <div>
        <SubContainer fixed={fixed} {...rest}>
          <ShareContainer visible={!work.secret}>
            <>
              <ShareText noteMode={note}>
                <FontAwesomeIcon icon={faShareAlt} color="#222222" size={16} />{' '}
                {t('share')}
              </ShareText>
              <a
                href={getTwitterUrl(work, note, staticContext)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledShareButton
                  icon={
                    <FontAwesomeIcon
                      icon={['fab', 'twitter']}
                      color="#1CC7FF"
                      size={18}
                    />
                  }
                />
              </a>
              <a
                href={getFacebookUrl(work, note, staticContext)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledShareButton
                  icon={
                    <FontAwesomeIcon
                      icon={['fab', 'facebook-square']}
                      color="#3B5998"
                      size={18}
                    />
                  }
                />
              </a>
              {work.type === 'image' && (
                <a
                  href={getPinterestUrl(work, note, staticContext)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledShareButton
                    icon={
                      <FontAwesomeIcon
                        icon={['fab', 'pinterest-square']}
                        color="#e60023"
                        size={18}
                      />
                    }
                  />
                </a>
              )}
              <CopyWorkCodeButton
                fullyRound
                text={t('copyLink')}
                onClick={copyProfileUrl}
              />
            </>
          </ShareContainer>
        </SubContainer>
      </div>
    );
  },
);

export default translate('workPage')(ShareWorkOrNote);
