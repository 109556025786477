import React from 'react';
import { pure } from 'recompose';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const VideoContainer = styled.div`
  background-color: #000;
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 10px;
  width: 100%;
`;

const renderVideoWork = video => {
  if (video.platform === 'tiktok') {
    // https://developers.tiktok.com/doc/embed-videos
    return (
      <blockquote
        key={video.video_id}
        className="tiktok-embed"
        cite={video.url}
        data-video-id={video.video_id}
        style={{ maxWidth: '605px', minWidth: '325px' }}
      >
        <section />
      </blockquote>
    );
  }

  if (video.platform === 'niconico') {
    return (
      <VideoContainer>
        <iframe
          src={`https://embed.nicovideo.jp/watch/${video.video_id}?jsapi=1`}
          title={video.video_id}
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </VideoContainer>
    );
  }
  return (
    <VideoContainer key={video.video_id}>
      <ReactPlayer
        url={video.url}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        controls
      />
    </VideoContainer>
  );
};

const VideoWorks = pure(({ videos }) => {
  return <>{videos.map(video => renderVideoWork(video))}</>;
});

export default VideoWorks;
