import React from 'react';
import styled, { keyframes } from 'styled-components';

const BasicLoaderContainer = styled.div`
  height: 200px;
  width: 100%;
  margin: 10px 0;
  border-radius: 5px;
  background: transparent;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const animation = keyframes`
  0%, 100% { opacity: 1 }
  50% { opacity: 0 }
`;

const SpinnerContainer = styled.div`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const SpinnerBounce3 = styled.div`
  width: 10px;
  height: 10px;
  background-color: rgb(16, 124, 232);
  border-radius: 100%;
  display: inline-block;
  animation: ${animation} 1.2s infinite ease-in-out both;
  margin-right: 10px;
`;

const SpinnerBounce1 = styled(SpinnerBounce3)`
  animation-delay: -0.32s;
`;

const SpinnerBounce2 = styled(SpinnerBounce3)`
  animation-delay: -0.16s;
`;

const BasicLoader = () => (
  <BasicLoaderContainer>
    <SpinnerContainer>
      <SpinnerBounce1 />
      <SpinnerBounce2 />
      <SpinnerBounce3 />
    </SpinnerContainer>
  </BasicLoaderContainer>
);

export default BasicLoader;
