import React from 'react';
import styled from 'styled-components';

const DefaultImageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FitImage = React.forwardRef((props, ref) => {
  const {
    customContainer,
    className,
    style,
    src,
    srcset,
    alt,
    ...restProps
  } = props;
  const Container = customContainer || DefaultImageContainer;
  const sizes = '100vw';
  return (
    <Container>
      <StyledImage
        alt={alt}
        className={className}
        style={style}
        ref={ref}
        src={src}
        srcSet={srcset && srcset}
        sizes={srcset && sizes}
        {...restProps}
      />
    </Container>
  );
});

export default FitImage;
