import React from 'react';
import styled from 'styled-components';

const ShareText = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.04rem;
  color: ${({ textColor }) => textColor || '#828282'};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;
const IconContainer = styled.div`
  margin-right: 6px;
`;

const ShareButton = props => {
  const { children, icon, className, onPress, textColor } = props;
  return (
    <ButtonContainer className={className} onClick={onPress}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <ShareText textColor={textColor}>{children}</ShareText>
    </ButtonContainer>
  );
};

export default ShareButton;
