import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { translate } from 'util/Translator';
import { withResponsive } from 'util/Responsive';
import FontAwesomeIcon from 'components/FontAwesomeIcon';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: center;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 0 -16px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  max-height: 40px;
  min-height: 35px;
  border-radius: 20px;
  padding: 0 20px;
  font-weight: ${({ active }) => active && 'bold'};
  background-color: ${({ active }) => active && '#F2F2F2'};
  color: ${({ chosenTextColor, active }) =>
    chosenTextColor === 'white' && !active ? 'white' : '#222222'};
  white-space: pre;
  cursor: pointer;
`;

const ArrowRight = styled(FontAwesomeIcon)`
  display: ${({ active }) => (active === 'true' ? 'flex' : 'none')};
  margin-left: 10px;
`;

const ArrowLeft = styled(FontAwesomeIcon)`
  display: ${({ active }) => (active === 'true' ? 'flex' : 'none')};
  margin-right: 10px;
`;

const StyledLink = styled(Link)`
  color: initial;
`;

const CategoryButton = ({
  withLink,
  to,
  active,
  children,
  onClick,
  chosenTextColor,
}) => {
  if (withLink) {
    return (
      <StyledLink to={to}>
        <Button active={active} chosenTextColor={chosenTextColor}>
          {children}
        </Button>
      </StyledLink>
    );
  }
  return (
    <Button onClick={onClick} active={active} chosenTextColor={chosenTextColor}>
      {children}
    </Button>
  );
};

const FilterCategories = props => {
  const {
    data,
    onClickCategory,
    onClickAll,
    selectedCategory,
    onScroll,
    leftArrow,
    rightArrow,
    category,
    withLink,
    toAllCategories,
    chosenTextColor,
  } = props;

  const onRightScroll = () => {
    category.current.scrollBy(category.current.clientWidth, 0);
  };

  const onLeftScroll = () => {
    category.current.scrollBy(-category.current.clientWidth, 0);
  };

  return (
    <FilterContainer>
      <ArrowLeft
        onClick={onLeftScroll}
        icon={['fas', 'angle-left']}
        color="#BDBDBD"
        size={24}
        active={leftArrow ? 'true' : 'false'}
      />
      <CategoriesContainer ref={category} id="category" onScroll={onScroll}>
        <CategoryButton
          withLink={withLink}
          to={toAllCategories}
          onClick={onClickAll}
          active={!selectedCategory}
          chosenTextColor={chosenTextColor}
        >
          All
        </CategoryButton>
        {data.map(cat => (
          <CategoryButton
            key={cat.id}
            withLink={withLink}
            to={cat.to}
            onClick={() => (!withLink ? onClickCategory(cat) : null)}
            active={cat.active}
            chosenTextColor={chosenTextColor}
          >
            {cat.name}
          </CategoryButton>
        ))}
      </CategoriesContainer>
      <ArrowRight
        onClick={onRightScroll}
        icon={['fas', 'angle-right']}
        color="#BDBDBD"
        size={24}
        active={rightArrow ? 'true' : 'false'}
      />
    </FilterContainer>
  );
};

export default withResponsive(translate('workPage')(FilterCategories));
