import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { media } from 'util/Responsive';

const Container = styled.div`
  background-color: #fbfbfb;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: ${({ hide }) => (hide ? 'none' : 'block')};

  ${media.tablet`
    display: none;
  `}
`;

const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 90px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1092px;
  margin: 0 auto;
  gap: 30px;
`;

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
  color: #222;

  ${({ direction }) =>
    direction === 'left'
      ? css`
          flex-direction: row;
          grid-column: 1;
        `
      : css`
          flex-direction: row-reverse;
          grid-column: 2;
        `}
`;

const ItemTitleAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  flex: 1;

  ${({ direction }) =>
    direction === 'left'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

const ItemTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 130%;
  color: #222;
  letter-spacing: 0.48px;
  word-break: break-word;
`;

const ItemImage = styled.img`
  width: 145px;
  max-height: 90px;
  border-radius: 10px;
  object-fit: cover;
`;

const WorkNavigationItem = ({ direction, id, title, image }) => {
  return (
    <ItemLink to={`/works/${id}`} direction={direction}>
      <ItemTitleAndIconContainer direction={direction}>
        <FontAwesomeIcon
          icon={direction === 'left' ? faArrowLeft : faArrowRight}
          color="#222"
          size={24}
        />
        <ItemTitle>{title}</ItemTitle>
      </ItemTitleAndIconContainer>
      <ItemImage src={image} width={145} />
    </ItemLink>
  );
};

const WorkPageNavigationBar = ({ prevWork, nextWork }) => {
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (e.key === 'ArrowLeft') {
        const modalElement = document.querySelector('.ReactModal__Overlay');
        if (prevWork && !modalElement) {
          history.push(`/works/${prevWork.id}`);
          window.scrollTo(0, 0);
        }
      }
    });
    document.addEventListener('keydown', e => {
      if (e.key === 'ArrowRight') {
        const modalElement = document.querySelector('.ReactModal__Overlay');
        if (nextWork && !modalElement) {
          history.push(`/works/${nextWork.id}`);
          window.scrollTo(0, 0);
        }
      }
    });
  }, []);

  return (
    <Container hide={!prevWork && !nextWork}>
      <SubContainer>
        {prevWork && (
          <WorkNavigationItem
            id={prevWork.id}
            title={prevWork.title}
            image={prevWork.thumbnail}
            direction="left"
          />
        )}
        {nextWork && (
          <WorkNavigationItem
            id={nextWork.id}
            title={nextWork.title}
            image={nextWork.thumbnail}
            direction="right"
          />
        )}
      </SubContainer>
    </Container>
  );
};

export default WorkPageNavigationBar;
