const checkBackgroundColorSelected = themeType => {
  const headerTypes = ['default', 'image'];
  const hasCorrectType = headerTypes.includes(themeType);
  if (!hasCorrectType) throw new Error();

  return themeType;
};

const checkSavedUserColorConfig = user => {
  try {
    const headerData = user.header_data;

    // Check theme. Throw error to send to default theme.
    const userThemeSelected = checkBackgroundColorSelected(
      headerData.header_theme_selection,
    );

    if (!userThemeSelected || userThemeSelected === 'default')
      throw new Error();

    // If we get to here image has been chosen
    const userImageTypeSelected = headerData.header_image_type;

    const savedBackgroundColor =
      userImageTypeSelected === 'profile'
        ? headerData.header_profile_background_color
        : headerData.header_work_background_color;

    const textColorFromBackground =
      userImageTypeSelected === 'profile'
        ? headerData.header_profile_text_color
        : headerData.header_work_text_color;

    return {
      user,
      textColorFromBackground,
      savedBackgroundColor,
      userThemeSelected,
    };
  } catch (error) {
    return {
      textColorFromBackground: 'black',
      savedBackgroundColor: null,
      userThemeSelected: 'default',
    };
  }
};

export default checkSavedUserColorConfig;
