import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { init } from '@sentry/browser';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { ToastContainer } from 'react-toastify';
import {
  faFacebook,
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faPinterestSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faUserCircle,
  faMapMarkerAlt,
  faGlobe,
  faHandPaper,
  faUserTag,
  faEye,
  faChevronLeft,
  faUsers,
  faCommentDots,
  faLock,
  faAngleLeft,
  faAngleRight,
  faExpandArrowsAlt,
  faStickyNote,
  faImage,
  faVolumeUp,
  faVideo,
  faCube,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import ErrorBoundary from 'components/ErrorBoundary';
import Notifications from 'components/Notifications/Notifications';
import ScrollToTop from 'util/ScrollToTop';
import { TranslationProvider } from 'util/Translator';
import { ResponsiveProvider, media } from 'util/Responsive';
import translations from 'services/translations';
import 'normalize.css';
import './App.css';

import 'react-toastify/dist/ReactToastify.css';

init({
  dsn: process.env.RAZZLE_SENTRY_DSN,
  environment: process.env.RAZZLE_SENTRY_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event;
      if (target.textContent) {
        // eslint-disable-next-line no-param-reassign
        breadcrumb.message = `${breadcrumb.message} (${target.textContent})`;
      }
    }
    return breadcrumb;
  },
});

config.autoAddCss = false;

library.add(
  faFacebook,
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faPinterestSquare,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faUserCircle,
  faMapMarkerAlt,
  faGlobe,
  faHandPaper,
  faUserTag,
  faEye,
  faChevronLeft,
  faUsers,
  faCommentDots,
  faLock,
  faAngleLeft,
  faAngleRight,
  faExpandArrowsAlt,
  faStickyNote,
  faImage,
  faVolumeUp,
  faVideo,
  faCube,
  faYoutube,
);

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
`;

const ContentContainer = styled.div`
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  width: ${({ wide }) => (wide ? '100%' : '1092px')};
  margin: 0 auto;
  z-index: 0;
  min-height: 100vh;
  ${media.laptop`
    width: 100%;
  `}
  ${media.tablet`
    z-index: ${({ noteMode }) => (noteMode ? 1000 : 0)};
  `}
`;

const ContentSubContainer = styled.div`
  ${media.laptop`
    padding: ${({ wide }) => (wide ? '0' : '0 20px')};
  `}
`;

const InnerApp = ({ routes, staticContext, initialData, extraData }) => {
  // hacky way for now to set BG on all profile
  const user = useSelector(state => state.user);
  const isProfilePage = initialData[0]?.match?.path === '/';

  return (
    <TranslationProvider data={translations} staticContext={staticContext}>
      <ResponsiveProvider staticContext={staticContext}>
        <ScrollToTop>
          <ErrorBoundary>
            <Helmet>
              <meta property="og:type" content="website" />
            </Helmet>
            <Switch>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index} // eslint-disable-line react/no-array-index-key
                    path={route.path}
                    exact={route.exact}
                    render={props => {
                      const matchedInitialData = route.path
                        ? initialData.find(d => d.match.path === route.path)
                        : null;
                      return (
                        <PageContainer
                          backgroundColor={user?.hexBackgroundColor || 'white'}
                        >
                          <ContentContainer
                            noteMode={route.noteMode}
                            wide={route.wide}
                            backgroundColor={
                              (isProfilePage && user?.hexBackgroundColor) ||
                              'white'
                            }
                          >
                            <ContentSubContainer wide={route.wide}>
                              {React.createElement(route.component, {
                                ...props,
                                initialData: matchedInitialData,
                                ...extraData,
                                ...route.extraProps,
                              })}
                            </ContentSubContainer>
                          </ContentContainer>
                        </PageContainer>
                      );
                    }}
                    context={staticContext}
                  />
                );
              })}
            </Switch>
            <Notifications />
          </ErrorBoundary>
        </ScrollToTop>
        <ToastContainer />
      </ResponsiveProvider>
    </TranslationProvider>
  );
};

const App = ({ store, ...rest }) => {
  return (
    <Provider store={store}>
      <InnerApp {...rest} />
    </Provider>
  );
};

export default App;
