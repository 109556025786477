import React, { Fragment } from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import CircleAvatar from 'components/CircleAvatar';
import { translate } from 'util/Translator';

const AuthorWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-self: flex-start;
  align-items: center;
  flex: 1;
`;

const AuthorTitle = styled.div`
  font-family: 'Noto Sans JP';
  color: #4f4f4f;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
`;

const AuthorName = styled.div`
  color: #4f4f4f;
  font-size: 15px;
  font-weight: bold;
  margin-left: 5px;
  flex: 1;
`;

const NoteAuthor = pure(({ t, note }) => (
  <Fragment>
    {note && note.user.profile.avatar && (
      <AuthorWrap>
        <AuthorTitle>{t('postedBy')}</AuthorTitle>
        <CircleAvatar size={23} image={note.user.profile.avatar.thumb2x} />
        <AuthorName>{note.user.profile.name}</AuthorName>
      </AuthorWrap>
    )}
    {!note && <div />}
  </Fragment>
));

export default translate('notes')(NoteAuthor);
