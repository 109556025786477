import React from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

const FontAwesomeIcon = ({ icon, size, color, className, ...restProps }) => {
  return (
    <FAIcon
      icon={icon}
      className={className}
      style={{
        fontSize: size,
        color,
      }}
      {...restProps}
    />
  );
};

export default FontAwesomeIcon;
