export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH';
export const USER_PROFILE_LOAD = 'USER_PROFILE_LOAD';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const USER_PROFILE_CLEAR = 'USER_PROFILE_CLEAR';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR';

export const USER_WORK_UPDATE_POSITION_SUCCESS =
  'USER_WORK_UPDATE_POSITION_SUCCESS';

export const USER_WORKS_FETCH = 'USER_WORKS_FETCH';
export const USER_WORKS_LOAD = 'USER_WORKS_LOAD';
export const USER_WORKS_ERROR = 'USER_WORKS_ERROR';
export const USER_WORKS_CLEAR = 'USER_WORKS_CLEAR';

export const AUTH_USER_WORKS_FETCH = 'AUTH_USER_WORKS_FETCH';
export const AUTH_USER_WORKS_LOAD = 'AUTH_USER_WORKS_LOAD';
export const AUTH_USER_WORKS_ERROR = 'AUTH_USER_WORKS_ERROR';
export const AUTH_USER_WORKS_CLEAR = 'AUTH_USER_WORKS_CLEAR';

export const AUTH_USER_PRIVATE_WORKS_FETCH = 'AUTH_USER_PRIVATE_WORKS_FETCH';
export const AUTH_USER_PRIVATE_WORKS_LOAD = 'AUTH_USER_PRIVATE_WORKS_LOAD';
export const AUTH_USER_PRIVATE_WORKS_ERROR = 'AUTH_USER_PRIVATE_WORKS_ERROR';
export const AUTH_USER_PRIVATE_WORKS_CLEAR = 'AUTH_USER_PRIVATE_WORKS_CLEAR';

export const USER_WORKS_CATEGORIES_FETCH = 'USER_WORKS_CATEGORIES_FETCH';
export const USER_WORKS_CATEGORIES_LOAD = 'USER_WORKS_CATEGORIES_LOAD';
export const USER_WORKS_CATEGORIES_ERROR = 'USER_WORKS_CATEGORIES_ERROR';

export const COLLECTION_FETCH = 'COLLECTION_FETCH';
export const COLLECTION_LOAD = 'COLLECTION_LOAD';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';

export const CREATE_COLLECTION_REQUEST = 'CREATE_COLLECTION_REQUEST';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_ERROR = 'CREATE_COLLECTION_ERROR';

export const UPDATE_COLLECTION_REQUEST = 'UPDATE_COLLECTION_REQUEST';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_ERROR = 'UPDATE_COLLECTION_ERROR';

export const DELETE_COLLECTION_REQUEST = 'DELETE_COLLECTION_REQUEST';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_ERROR = 'DELETE_COLLECTION_ERROR';

export const OWN_COLLECTIONS_FETCH = 'USER_COLLECTIONS_FETCH';
export const OWN_COLLECTIONS_LOAD = 'USER_COLLECTIONS_LOAD';
export const OWN_COLLECTIONS_ERROR = 'USER_COLLECTIONS_ERROR';

export const SINGLE_WORK_FETCH = 'SINGLE_WORK_FETCH';
export const SINGLE_WORK_LOAD = 'SINGLE_WORK_LOAD';
export const SINGLE_WORK_ERROR = 'SINGLE_WORK_ERROR';
export const SINGLE_WORK_CLEAR = 'SINGLE_WORK_CLEAR';

export const SINGLE_WORK_NOTESLIST_FETCH = 'SINGLE_WORK_NOTESLIST_FETCH';
export const SINGLE_WORK_NOTESLIST_LOAD = 'SINGLE_WORK_NOTESLIST_LOAD';
export const SINGLE_WORK_NOTESLIST_ERROR = 'SINGLE_WORK_NOTESLIST_ERROR';

export const RELATED_NOTESLIST_FETCH = 'RELATED_NOTESLIST_FETCH';
export const RELATED_NOTESLIST_LOAD = 'RELATED_NOTESLIST_LOAD';
export const RELATED_NOTESLIST_ERROR = 'RELATED_NOTESLIST_ERROR';

export const NOTE_FETCH = 'NOTE_FETCH';
export const NOTE_LOAD = 'NOTE_LOAD';
export const NOTE_ERROR = 'NOTE_ERROR';

export const NOTE_UPDATE_REQUEST = 'NOTE_UPDATE_REQUEST';
export const NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS';
export const NOTE_UPDATE_ERROR = 'NOTE_UPDATE_ERROR';

export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST';
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS';
export const NOTE_DELETE_ERROR = 'NOTE_DELETE_ERROR';

export const NOTE_SUBMIT_REQUEST = 'NOTE_SUBMIT_REQUEST';
export const NOTE_SUBMIT_SUCCESS = 'NOTE_SUBMIT_SUCCESS';
export const NOTE_SUBMIT_ERROR = 'NOTE_SUBMIT_ERROR';

export const AUTH_FETCH = 'AUTH_FETCH';
export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR = 'AUTH_CLEAR';

export const STATS_FETCH = 'STATS_FETCH';
export const STATS_LOAD = 'STATS_LOAD';
export const STATS_ERROR = 'STATS_ERROR';

export const WORK_SUBMIT_REQUEST = 'WORK_SUBMIT_REQUEST';
export const WORK_SUBMIT_SUCCESS = 'WORK_SUBMIT_SUCCESS';
export const WORK_SUBMIT_ERROR = 'WORK_SUBMIT_ERROR';

export const WORK_UPDATE_REQUEST = 'WORK_UPDATE_REQUEST';
export const WORK_UPDATE_SUCCESS = 'WORK_UPDATE_SUCCESS';
export const WORK_UPDATE_ERROR = 'WORK_UPDATE_ERROR';

export const WORK_UPDATE_PRIVATE_REQUEST = 'WORK_UPDATE_PRIVATE_REQUEST';
export const WORK_UPDATE_PRIVATE_SUCCESS = 'WORK_UPDATE_PRIVATE_SUCCESS';
export const WORK_UPDATE_PRIVATE_ERROR = 'WORK_UPDATE_PRIVATE_ERROR';

export const WORK_INVITATION_REFRESH_REQUEST =
  'WORK_INVITATION_REFRESH_REQUEST';
export const WORK_INVITATION_REFRESH_SUCCESS =
  'WORK_INVITATION_REFRESH_SUCCESS';
export const WORK_INVITATION_REFRESH_ERROR = 'WORK_INVITATION_REFRESH_ERROR';

export const WORK_SECRET_TOKEN_REFRESH_REQUEST =
  'WORK_SECRET_TOKEN_REFRESH_REQUEST';
export const WORK_SECRET_TOKEN_REFRESH_SUCCESS =
  'WORK_SECRET_TOKEN_REFRESH_SUCCESS';
export const WORK_SECRET_TOKEN_REFRESH_ERROR =
  'WORK_SECRET_TOKEN_REFRESH_ERROR';

export const WORK_CREATIVE_ROLE_POST_REQUEST =
  'WORK_CREATIVE_ROLE_POST_REQUEST';
export const WORK_CREATIVE_ROLE_POST_SUCCESS = 'WORK_CREATIVE_ROLE_SUCCESS';
export const WORK_CREATIVE_ROLE_POST_ERROR = 'WORK_CREATIVE_ROLE_ERROR';

export const WORK_CREATIVE_ROLE_UPDATE_REQUEST =
  'WORK_CREATIVE_ROLE_UPDATE_REQUEST';
export const WORK_CREATIVE_ROLE_UPDATE_SUCCESS =
  'WORK_CREATIVE_ROLE_UPDATE_SUCCESS';
export const WORK_CREATIVE_ROLE_UPDATE_ERROR =
  'WORK_CREATIVE_ROLE_UPDATE_ERROR';

export const WORK_CREATIVE_ROLE_DELETE_REQUEST =
  'WORK_CREATIVE_ROLE_DELETE_REQUEST';
export const WORK_CREATIVE_ROLE_DELETE_SUCCESS =
  'WORK_CREATIVE_ROLE_DELETE_SUCCESS';
export const WORK_CREATIVE_ROLE_DELETE_ERROR =
  'WORK_CREATIVE_ROLE_DELETE_ERROR';

export const LOCALE_SET = 'LOCALE_SET';

export const DISCOVER_NOTES_REQUEST = 'DISCOVER_NOTES_REQUEST';
export const DISCOVER_NOTES_SUCCESS = 'DISCOVER_NOTES_SUCCESS';
export const DISCOVER_NOTES_ERROR = 'DISCOVER_NOTES_ERROR';

export const DISCOVER_WORKS_REQUEST = 'DISCOVER_WORKS_REQUEST';
export const DISCOVER_WORKS_SUCCESS = 'DISCOVER_WORKS_SUCCESS';
export const DISCOVER_WORKS_ERROR = 'DISCOVER_WORKS_ERROR';
export const DISCOVER_WORKS_CLEAR = 'DISCOVER_WORKS_CLEAR';

export const DISCOVER_USERS_REQUEST = 'DISCOVER_USERS_REQUEST';
export const DISCOVER_USERS_SUCCESS = 'DISCOVER_USERS_SUCCESS';
export const DISCOVER_USERS_ERROR = 'DISCOVER_USERS_ERROR';

export const EDITOR_PICKS_REQUEST = 'EDITOR_PICKS_REQUEST';
export const EDITOR_PICKS_SUCCESS = 'EDITOR_PICKS_SUCCESS';
export const EDITOR_PICKS_ERROR = 'EDITOR_PICKS_ERROR';

export const LANDING_PAGE_REQUEST = 'LANDING_PAGE_REQUEST';
export const LANDING_PAGE_SUCCESS = 'LANDING_PAGE_SUCCESS';
export const LANDING_PAGE_ERROR = 'LANDING_PAGE_ERROR';

export const WORK_CATEGORY_UPDATE_REQUEST = 'WORK_CATEGORY_UPDATE_REQUEST';
export const WORK_CATEGORY_UPDATE_SUCCESS = 'WORK_CATEGORY_UPDATE_SUCCESS';
export const WORK_CATEGORY_UPDATE_ERROR = 'WORK_CATEGORY_UPDATE_ERROR';

export const WORK_CATEGORY_DELETE_REQUEST = 'WORK_CATEGORY_DELETE_REQUEST';
export const WORK_CATEGORY_DELETE_SUCCESS = 'WORK_CATEGORY_DELETE_SUCCESS';
export const WORK_CATEGORY_DELETE_ERROR = 'WORK_CATEGORY_DELETE_ERROR';

export const WORK_DELETE_REQUEST = 'WORK_DELETE_REQUEST';
export const WORK_DELETE_SUCCESS = 'WORK_DELETE_SUCCESS';
export const WORK_DELETE_ERROR = 'WORK_DELETE_ERROR';

export const PRIVATE_USER_SESSION_REQUEST = 'PRIVATE_USER_SESSION_REQUEST';
export const PRIVATE_USER_SESSION_SUCCESS = 'PRIVATE_USER_SESSION_SUCCESS';
export const PRIVATE_USER_SESSION_ERROR = 'PRIVATE_USER_SESSION_ERROR';

export const GLOBAL_UI_CHANGE = 'GLOBAL_UI_CHANGE';

export const SET_VIEW_LAYOUT_POSITION = 'SET_VIEW_LAYOUT_POSITION';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const DESTROY_NOTIFICATION = 'DESTROY_NOTIFICATION';
