import React from 'react';
import Markdown from 'react-markdown';
import styled, { css } from 'styled-components';

const textDefaultStyle = css`
  letter-spacing: 0.03em;
  line-height: 1.8em;
  word-break: break-word;
  color: ${({ textColor }) => textColor || '#fff'};
`;

const Container = styled.div`
  p {
    ${textDefaultStyle}
  }
  span {
    ${textDefaultStyle}
  }
  block {
    margin-bottom: 20px;
    display: inline;
  }
  blockquote {
    border-left: 5px solid #aaaaaa;
    background-color: transparent;
    padding: 10px 10px 0 15px;
    margin: 0;
  }
  h1 {
    ${textDefaultStyle}
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  h2 {
    ${textDefaultStyle}
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h3 {
    ${textDefaultStyle}
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h4 {
    ${textDefaultStyle}
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h5 {
    ${textDefaultStyle}
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h6 {
    ${textDefaultStyle}
    font-size: 12px;
    margin-top: -18px;
    margin-bottom: 15px;
  }
  hr {
    display: flex;
    align-self: stretch;
    height: 1px;
    background-color: #333333;
    margin: 8px 0;
  }
  text: {
    ${textDefaultStyle}
    align-self: flex-start;
    font-size: 16px;
  }
  strong: {
    ${textDefaultStyle}
    font-weight: bold;
  }
  em {
    ${textDefaultStyle}
    font-style: italic;
  }
  del {
    text-decoration-line: line-through;
  }
  u {
    text-decoration-line: underline;
  }
  a {
    display: inline;
    color: ${({ textColor }) => textColor || '#fff'};
    text-decoration: underline;
  }
  li {
    color: ${({ textColor }) => textColor || '#fff'};
  }
  ol {
    color: ${({ textColor }) => textColor || '#fff'};
  }
`;

const ForiioMarkdown = ({ md, textColor }) => (
  <Container textColor={textColor}>
    <Markdown source={md} linkTarget="_blank" />
  </Container>
);

export default ForiioMarkdown;
