import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from 'util/Responsive';

const Container = styled.div`
  position: ${({ nonFixed }) => (nonFixed ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 10000;
  background-color: #fbfbfb;
  ${media.tablet`
    height: 70px;
    width: ${({ appWidth }) => appWidth};
    ${({ invertColor }) =>
      !invertColor &&
      css`
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #e0e0e0;
      `};
  `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 40px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: rgb(88, 99, 248);
  text-align: center;
`;

class Header extends Component {
  state = {};

  render() {
    const { backText } = this.props;
    return (
      <Container>
        <StyledLink to="/">
          <Title>{backText}</Title>
        </StyledLink>
      </Container>
    );
  }
}

export default Header;
