/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import ForiioMarkdown from 'components/ForiioMarkdown';
import FullWidthAspectImage from 'components/AspectImage';

const Container = styled.div``;
const DetailsItemWrap = styled.div`
  ${({ image }) => (image ? 'margin: 0 0 30px;' : 'margin: 0 0 10px;')}
`;

const NoteMarkdownViewer = ({ data }) => {
  const imagePattern = /((?:http(?:s?):)(?:[/|\.|\w|\s|\-|\,|\@|\\])*\.(?:jpg|gif|png).*)/g;

  const renderMarkdownNote = () => {
    const imageSplitDetails = (data || '')
      .split(imagePattern)
      .filter(item => item !== '');

    return imageSplitDetails.map((fragment, index) => {
      if (fragment.substr(0, 4) !== 'http') {
        return (
          <DetailsItemWrap key={index}>
            <ForiioMarkdown
              md={fragment.replace('\\', '').trim()}
              textColor="#4F4F4F"
            />
          </DetailsItemWrap>
        );
      }

      return (
        <DetailsItemWrap key={index} image>
          <FullWidthAspectImage src={fragment} />
        </DetailsItemWrap>
      );
    });
  };
  return <Container>{renderMarkdownNote()}</Container>;
};

export default NoteMarkdownViewer;
