import React from 'react';
import BaseError from 'components/ErrorPage/BaseError';
import { translate } from 'util/Translator';

const Error403 = ({ staticContext, t }) => {
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.statusCode = 403;
  }
  return (
    <BaseError title={t('403Title')} description={t('403Description')}>
      <svg
        width="100%"
        height="258"
        viewBox="0 0 376 258"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M187.6 257.5C291.209 257.5 375.2 239.188 375.2 216.6C375.2 194.012 291.209 175.7 187.6 175.7C83.9914 175.7 0 194.012 0 216.6C0 239.188 83.9914 257.5 187.6 257.5Z"
          fill="#D7DBFF"
        />
        <path
          d="M321.9 1.5H50.5V200.8H321.9V1.5Z"
          fill="white"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M243 27.7H130V140.7H243V27.7Z"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M231.5 38.2H140.5V129.2H231.5V38.2Z"
          fill="#E7EEFF"
          stroke="#5863F8"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M29.4004 153.1C29.4004 153.1 61.6004 203.1 133.9 170.3"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M342.8 153.1C342.8 153.1 310.6 203.1 238.3 170.3"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M238.4 170.2C238.4 170.2 186.6 204.7 133.9 170.2"
          stroke="#D7DBFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M342.8 153.1C347.108 153.1 350.6 149.608 350.6 145.3C350.6 140.992 347.108 137.5 342.8 137.5C338.492 137.5 335 140.992 335 145.3C335 149.608 338.492 153.1 342.8 153.1Z"
          fill="#5863F8"
        />
        <path
          d="M342.8 153.1V222.1"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M133.9 170.3C138.207 170.3 141.7 166.808 141.7 162.5C141.7 158.192 138.207 154.7 133.9 154.7C129.592 154.7 126.1 158.192 126.1 162.5C126.1 166.808 129.592 170.3 133.9 170.3Z"
          fill="#5863F8"
        />
        <path
          d="M133.899 170.2V239.2"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M238.4 170.3C242.707 170.3 246.2 166.808 246.2 162.5C246.2 158.192 242.707 154.7 238.4 154.7C234.092 154.7 230.6 158.192 230.6 162.5C230.6 166.808 234.092 170.3 238.4 170.3Z"
          fill="#5863F8"
        />
        <path
          d="M238.399 170.2V239.2"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M29.3996 153.1C33.7074 153.1 37.1996 149.608 37.1996 145.3C37.1996 140.992 33.7074 137.5 29.3996 137.5C25.0918 137.5 21.5996 140.992 21.5996 145.3C21.5996 149.608 25.0918 153.1 29.3996 153.1Z"
          fill="#5863F8"
        />
        <path
          d="M29.3994 153.1V222.1"
          stroke="#5863F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M206.3 78.1C205 76.8 203.2 76.1 201.2 76.1V70.1C201.2 62.3 194.9 56.1 187.2 56.1C179.5 56.1 173.2 62.4 173.2 70.1V76.1H173.1C171.2 76.1 169.3 76.8 168 78.1C166.7 79.4 165.9 81.1 165.9 82.9V101.9C165.9 103.7 166.7 105.5 168 106.8C169.3 108.1 171.2 108.9 173.1 108.9H201.3C203.2 108.9 205 108.2 206.4 106.8C207.7 105.5 208.4 103.7 208.4 101.9V82.9C208.4 81.1 207.6 79.4 206.3 78.1ZM179.1 70.1C179.1 67.9 180 65.9 181.5 64.3C183 62.8 185.1 61.9 187.3 61.9C189.5 61.9 191.5 62.7 193 64.3C194.5 65.8 195.4 67.9 195.4 70.1V76.1H184.6H179.1V70.1ZM202.4 101.7C202.4 102.3 201.9 102.9 201.2 102.9H173C172.3 102.9 171.7 102.4 171.7 101.7V83.1C171.7 82.5 172.3 82 172.9 82H184.5H201.2C201.8 82 202.4 82.5 202.4 83.2V101.7V101.7Z"
          fill="#5863F8"
        />
        <path
          d="M192.2 90.3C192.2 87.5 190 85.3 187.2 85.3C184.4 85.3 182.2 87.5 182.2 90.3C182.2 92 183 93.4 184.3 94.3V98.1C184.3 99.2 184.7 99.7 185.8 99.7H188.6C189.7 99.7 190.2 99.2 190.2 98.1V94.3C191.4 93.5 192.2 92 192.2 90.3Z"
          fill="#5863F8"
        />
      </svg>
    </BaseError>
  );
};

export default translate('error')(Error403);
