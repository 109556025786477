import React from 'react';
import styled from 'styled-components';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
// import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { trackEvent } from 'util/analytics';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { media } from 'util/Responsive';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  margin-top: 11px;

  ${media.tablet`
    margin: 6px 0px 14px;
  `}
`;

const SNSButtonLinking = styled.a`
  margin-right: 5px;
`;

const SNSBaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 40px;
  height: 30px;
  width: 30px;
  font-size: 15px;
  font-weight: 700;
  background: rgba(133, 133, 133, 0.1);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const StyledSNSLinkButton = styled(SNSBaseButton)`
  background-color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.05)'};
`;

const StyledFontAwesomeIcon = styled(
  ({ chosenTextColor, defaultColor, ...restProps }) => (
    <FontAwesomeIcon {...restProps} />
  ),
)`
  color: ${({ chosenTextColor, defaultColor }) =>
    chosenTextColor === 'white' ? chosenTextColor : defaultColor};
`;

const ProfileSNSLinks = ({ userData, chosenTextColor, SNSLinks }) => {
  const trackSns = sns => {
    trackEvent({
      event: 'GAEvent',
      category: `click: ${sns} media link`,
      action: `Clicked:${sns} user link button`,
    });
  };

  const snsConfig = {
    twitter: {
      hrefKey: 'twitter_url',
      icon: faTwitter,
      defaultColor: '#1CC7FF',
      label: 'Twitter',
    },
    facebook: {
      hrefKey: 'facebook_url',
      icon: faFacebook,
      defaultColor: '#3B5998',
      label: 'Facebook',
    },
    youtube: {
      hrefKey: 'youtube_url',
      icon: faYoutube,
      defaultColor: '#ff0000',
      label: 'YouTube',
    },
    website: {
      hrefKey: 'website',
      icon: faGlobe,
      defaultColor: '#F4AD36',
      label: 'Web',
    },
    instagram: {
      hrefKey: 'instagram_url',
      icon: faInstagram,
      defaultColor: '#DF2662',
      label: 'Instagram',
    },
  };

  // Links come in as an array of arrays with key values. eg twitter, (twitter url)
  const renderSNSButton = link => {
    const config = snsConfig[link[0]];
    if (!config) return null;

    return (
      <SNSButtonLinking
        key={link[0]}
        href={userData[config.hrefKey]}
        target="_blank"
        onClick={() => trackSns(link[0])}
      >
        <StyledSNSLinkButton chosenTextColor={chosenTextColor}>
          <StyledFontAwesomeIcon
            chosenTextColor={chosenTextColor}
            icon={config.icon}
            defaultColor={config.defaultColor}
            size={15}
          />
        </StyledSNSLinkButton>
      </SNSButtonLinking>
    );
  };

  return (
    <Container>
      {SNSLinks?.map(link => {
        return renderSNSButton(link);
      })}
    </Container>
  );
};

export default ProfileSNSLinks;
