import React, { Fragment } from 'react';
import qs from 'qs';
import { Link, Route, matchPath } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import memoize from 'lodash.memoize';
import BasicLoader from 'components/BasicLoader';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import ImageWorks from 'components/WorkPage/ImageWorks';
import VideoWorks from 'components/WorkPage/VideoWorks';
import WebWorks from 'components/WorkPage/WebWorks';
import SoundWorks from 'components/WorkPage/SoundWorks';
import ThreeDModelWorks from 'components/WorkPage/ThreeDModelWorks';
import WorkDetailDesktop from 'components/WorkPage/WorkDetailDesktop';
import WorkCredits from 'components/WorkPage/WorkCredits';
import Header from 'components/Header';
import WorkpageNavigationBar from 'components/WorkPage/WorkPageNavigationBar';
import ShareWorkOrNote from 'components/WorkPage/ShareWorkOrNote';
import RelatedWorks from 'components/WorkPage/RelatedWorks';
import NoteBody from 'components/WorkPage/NoteBody';
import AboutModal from 'components/AboutModal';
import FloatingButton from 'components/FloatingButton';
import errorHandler from 'components/ErrorPage/errorHandler';
import { launchNotification } from 'components/Notifications/notifActions';
import Divider from 'components/Divider';
import { GrayButton } from 'components/Buttons';
import {
  getSingleWork,
  getSingleWorkNotesList,
  getUserProfile,
  getUserWorks,
} from 'services/api';
import { media, mediaMin, withResponsive, deviceSizes } from 'util/Responsive';
import { getBaseUrl, getOGUrl } from 'util/GetBaseUrl';
import { translate } from 'util/Translator';

const HeaderWrap = styled.div`
  width: 100%;
  position: relative;
  margin-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  ${media.tablet`
    margin-top: 30px;
    width: auto;
  `}
`;

const WorkAndDesktopNoteWrapContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: white;
`;

const WorkAndDesktopNoteWrap = styled.div`
  display: flex;
  max-width: 1500px;
  margin: 0 auto;
  flex-direction: row;
  align-items: stretch;
  width: 100% !important;

  ${mediaMin.tablet`
    height: calc(100vh - 100px);
  `}
`;

const WorkBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: baseline;
  transition: all 0.7s;
  flex: 1;
  width: 100%;
  background-color: white;
`;

const WorkContainer = styled.div`
  padding: 80px 20px 0px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  background-color: white;

  ${media.phone`
    padding: 20px;
  `}

  ${mediaMin.tablet`
    overflow-y: scroll;
    height: calc(100vh - 100px);
    ${({ isVerticalCenter }) =>
      isVerticalCenter
        ? css`
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 20px;
          `
        : css`
            padding: 80px 20px 0;
          `};
  `}
`;

const Title = styled.h1`
  font-size: 31px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  color: #333333;
  word-break: break-word;
  margin: 0;
  ${media.tablet`
    font-size: 25px;
  `}
`;

const AboutButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 23px;
`;

const BottomDivider = styled(Divider)`
  background-color: #f2f2f2;
  margin-top: 50px;
  margin-bottom: 25px;
  ${media.tablet`
    margin-top: 10px;
    margin-bottom: 20px;
  `}
`;

const WorkNotePageWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const AllWorkInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  transition: all 0.7s;
  width: 100%;
  background-color: white;
`;

const PrivateWorkFloatingButton = styled(FloatingButton)`
  && {
    background-color: #828282;
    border-radius: 15px;
    bottom: 30px;
    right: initial;
    left: 30px;
    text-align: left;
    :hover {
      background-color: rgb(123, 123, 123);
    }
    :active {
      background-color: rgb(108, 116, 116);
    }
    ${media.tablet`
      left: 15px;
      right: 15px;
      border-radius: 5px;
    `}
  }
`;

const PrivateWorkFloatingButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  ${media.tablet`
    padding: 9px 20px;
  `}
`;

const PrivateWorkFloatingButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 15px;
`;

const PrivateWorkFloatingButtonTitle = styled.div`
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #fbfbfb;
  ${media.tablet`
    font-size: 18px;
    line-height: 18px;
  `}
`;

const PrivateWorkFloatingButtonDescription = styled.div`
  margin-top: 8px;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #fbfbfb;
`;

class WorkPage extends React.Component {
  static getInitialData(ctx) {
    const { match, dispatch, queryString, screenName } = ctx;
    const { work_id } = match.params;
    const { secret } = queryString;
    return Promise.all([
      getUserWorks(dispatch, screenName),
      getUserProfile(dispatch, screenName),
      getSingleWork(dispatch, screenName, work_id, secret),
      getSingleWorkNotesList(dispatch, work_id, secret),
    ]).then(() => {
      return {
        match,
      };
    });
  }

  constructor(props) {
    super(props);
    const { location } = props;
    const isMatchNoteRoutes = !!matchPath(location.pathname, {
      path: '/works/:work_id/notes',
    });
    this.state = {
      hideNoteBody: !isMatchNoteRoutes,
      isOpenAboutModal: false,
      isShowNote: isMatchNoteRoutes,
      isOpenLightbox: false,
      selectedLightboxIndex: null,
      lightboxViewIndex: null,
    };
    this.memoizedMapImages = memoize(this.mapImages);
  }

  componentDidMount() {
    const { match, initialData, dispatch, location, screenName } = this.props;
    // Skip fetching if data fetched from server side
    if (
      !initialData ||
      match.params.work_id !== initialData.match.params.work_id
    ) {
      const queryString = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      this.constructor.getInitialData({
        match,
        dispatch,
        screenName,
        queryString,
      });
    }
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (!this.previousLocation && nextProps.history.action !== 'POP') {
      this.previousLocation = location;
    }
  }

  componentDidUpdate(prevProps) {
    const { work_id: oldWorkId, location: prevLocation, dispatch } = prevProps;
    const { work_id, location, match, screenName } = this.props;
    const prevIsMatchNoteRoutes = !!matchPath(prevLocation.pathname, {
      path: '/works/:work_id/notes',
    });
    const isMatchNoteRoutes = !!matchPath(location.pathname, {
      path: '/works/:work_id/notes',
    });
    if (isMatchNoteRoutes !== prevIsMatchNoteRoutes) {
      if (isMatchNoteRoutes) {
        this.setState({
          isShowNote: true,
          hideNoteBody: false,
        });
      } else {
        this.setState({
          isShowNote: false,
        });
      }
    }

    if (work_id && work_id !== oldWorkId) {
      const queryString = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      this.workPageTitlePosition = null;
      this.constructor.getInitialData({
        match,
        dispatch,
        queryString,
        screenName,
      });
      if (isMatchNoteRoutes) {
        this.setState({
          isShowNote: true,
          hideNoteBody: false,
        });
      } else {
        this.setState({
          isShowNote: false,
        });
      }
    }
  }

  handleOnPressOpenAboutModal = () => {
    this.setState({
      isOpenAboutModal: true,
    });
  };

  handleOnPressCloseAboutModal = () => {
    this.setState({
      isOpenAboutModal: false,
    });
  };

  handleOnClickCopyPrivateWorkUrl = () => {
    const { dispatch } = this.props;
    launchNotification(dispatch, { message: 'urlCopied', type: 'success' });
  };

  handleOnNoteTransitionEnd = () => {
    const { isShowNote, hideNoteBody } = this.state;
    if (!isShowNote && !hideNoteBody) {
      this.setState({
        hideNoteBody: true,
      });
    }
  };

  handleOnClickViewNotes = () => {
    this.scrollToWorkBody();
  };

  handleOnClickViewNote = () => {
    this.scrollToWorkBody();
  };

  handleOnToggleLightbox = index => {
    const { isOpenLightbox } = this.state;
    this.setState({
      isOpenLightbox: !isOpenLightbox,
      selectedLightboxIndex: index,
      lightboxViewIndex: index,
    });
  };

  handleOnLightboxViewIndeChange = index => {
    this.setState({
      lightboxViewIndex: index,
    });
  };

  scrollToWorkBody = () => {
    const { appWidth } = this.props;
    if (this.workBody && appWidth >= deviceSizes.tablet) {
      window.scrollTo({
        top: this.workBody.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  mapImages = images => {
    return images.map(image => {
      let imageType;
      let imageFileName;
      try {
        imageFileName = image.urls.detail2x
          .split('?')[0]
          .split('/')
          .pop();
        imageType = imageFileName.split('.').pop();
      } catch (err) {}
      return {
        ...image,
        id: image.id,
        url:
          imageType === 'gif'
            ? `${process.env.RAZZLE_CONTENT_CDN}/store/${imageFileName}`
            : image.urls.detail2x,
        srcset: imageType !== 'gif' ? image.srcset : null,
      };
    });
  };

  renderWorks = () => {
    const {
      work: { data },
    } = this.props;
    const {
      isOpenLightbox,
      selectedLightboxIndex,
      lightboxViewIndex,
    } = this.state;
    if (data.type === 'image') {
      const images = this.memoizedMapImages(data.images);
      return <ImageWorks images={images} workTitle={data.title} />;
      // return (
      //   <ImageWorks
      //     images={data.images}
      //     workTitle={data.title}
      //     isOpenLightbox={isOpenLightbox}
      //     onToggleLightbox={this.handleOnToggleLightbox}
      //     onLightboxViewIndexChange={this.handleOnLightboxViewIndeChange}
      //     selectedLightboxIndex={selectedLightboxIndex}
      //     lightboxViewIndex={lightboxViewIndex}
      //   />
      // );
    }
    if (data.type === 'video') {
      return <VideoWorks videos={data.videos} workTitle={data.title} />;
    }
    if (data.type === 'web_article') {
      return <WebWorks articles={data.web_articles} workTitle={data.title} />;
    }
    if (data.type === 'sound') {
      return <SoundWorks sounds={data.sounds} workTitle={data.title} />;
    }
    if (data.type === 'embedded3d') {
      return (
        <ThreeDModelWorks models={data.embedded3ds} workTitle={data.title} />
      );
    }
    return null;
  };

  render() {
    const {
      t,
      user,
      work,
      works,
      noteMode,
      appWidth,
      staticContext,
      location,
    } = this.props;
    const { data, loading, notesList } = work;
    const { isOpenAboutModal, isShowNote } = this.state;
    const matchNotesRoute = matchPath(location.pathname, {
      path: '/works/:work_id/notes',
      exact: true,
    });
    const isMobile = appWidth < deviceSizes.tablet;
    if (!data || !data.author || loading) {
      return <BasicLoader />;
    }
    const hasTiktokVideo =
      data.type === 'video' &&
      data.videos.some(video => video.platform === 'tiktok');

    const userWorks = user?.workIDs?.map(workId => works[workId]?.data);
    const currentWorkIndex = userWorks?.findIndex(w => +w.id === +work.data.id);

    const prevWork =
      currentWorkIndex !== -1 && currentWorkIndex > 0 && userWorks?.length > 0
        ? userWorks[currentWorkIndex - 1]
        : null;
    const nextWork =
      currentWorkIndex !== -1 && currentWorkIndex < userWorks?.length - 1
        ? userWorks[currentWorkIndex + 1]
        : null;

    return (
      <WorkNotePageWrap isMobile={isMobile}>
        {data && data.author && (
          <Helmet>
            <title>{`${data.title} - ${data.author.profile.name}`}</title>
            <meta name="description" content={`${data.description}`} />
            <meta
              property="og:title"
              content={`${data.title} - ${data.author.profile.name}`}
            />
            <meta property="og:url" content={getOGUrl(staticContext)} />
            <meta property="og:description" content={`${data.description}`} />
            <meta property="og:image" content={`${data.thumbnail}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={`${data.title} - ${data.author.profile.name}`}
            />
            <meta
              name="twitter:text:title"
              content={`${data.title} - ${data.author.profile.name}`}
            />
            <meta name="twitter:description" content={`${data.description}`} />
            <meta name="twitter:image" content={`${data.thumbnail}`} />
            <link rel="shortcut icon" href={data.author.profile.avatar.thumb} />
          </Helmet>
        )}
        {!isMobile && <Header backText={t('back')} />}
        <AllWorkInfoWrap>
          {isMobile && (
            <HeaderWrap>
              <Title>{data.title}</Title>
              {data.description && data.description.length > 0 ? (
                <AboutButtonContainer>
                  <GrayButton
                    fullyRound
                    text={t('about')}
                    onPress={this.handleOnPressOpenAboutModal}
                  />
                </AboutButtonContainer>
              ) : null}
              <WorkCredits
                workOwnerId={data.owner_id}
                workId={data.id}
                credits={data.credits}
              />
            </HeaderWrap>
          )}
          <WorkAndDesktopNoteWrapContainer>
            <WorkAndDesktopNoteWrap>
              <WorkBody>
                <WorkContainer
                  isVerticalCenter={
                    (data.type === 'video' &&
                      data.videos &&
                      data.videos.length === 1) ||
                    (data.type === 'sound' &&
                      data.sounds &&
                      data.sounds.length === 1) ||
                    data.type === 'web_article'
                  }
                >
                  {this.renderWorks()}
                  {isMobile && (
                    <Fragment>
                      <BottomDivider />
                      {data && (
                        <ShareWorkOrNote
                          work={data}
                          staticContext={staticContext}
                        />
                      )}
                    </Fragment>
                  )}
                  {isMobile && notesList && !!notesList.length && !isShowNote && (
                    <Link
                      to={`/works/${work.data.id}/notes${
                        notesList.length === 1 ? `/${notesList[0].id}` : ''
                      }${
                        work.data.secret ? `?secret=${work.data.secret}` : ''
                      }`}
                    >
                      <FloatingButton
                        icon={
                          <FontAwesomeIcon
                            icon={['fas', 'comment-dots']}
                            color="#F2F2F2"
                            size={28}
                          />
                        }
                        label={
                          notesList.length === 1
                            ? t('viewNote', { number: notesList.length })
                            : t('viewNotes', { number: notesList.length })
                        }
                        hideBadge
                      />
                    </Link>
                  )}
                </WorkContainer>
              </WorkBody>
              {isMobile ? (
                <Route path="/works/:work_id/notes">
                  {({ match, ...rest }) => (
                    <Transition timeout={0} in={!!match}>
                      {state => (
                        <NoteBody
                          className={state}
                          work={work}
                          mobile={isMobile}
                          noteMode={!matchNotesRoute}
                          {...rest}
                        />
                      )}
                    </Transition>
                  )}
                </Route>
              ) : (
                <WorkDetailDesktop
                  work={work}
                  mobile={isMobile}
                  noteMode={!matchNotesRoute}
                  staticContext={staticContext}
                />
              )}
            </WorkAndDesktopNoteWrap>
          </WorkAndDesktopNoteWrapContainer>
          <WorkpageNavigationBar prevWork={prevWork} nextWork={nextWork} />
          {Boolean(data.related_works && data.related_works.length > 0) && (
            <RelatedWorks data={data} />
          )}

          {isOpenAboutModal && (
            <AboutModal
              isVisible={isOpenAboutModal}
              title={t('about')}
              description={data.description}
              onClose={this.handleOnPressCloseAboutModal}
            />
          )}
        </AllWorkInfoWrap>
        {data && data.secret && !noteMode && !isShowNote && (
          <CopyToClipboard
            text={`${getBaseUrl(staticContext)}/works/${work.data.id}/?secret=${
              work.data.secret
            }`}
          >
            <PrivateWorkFloatingButton
              hideBadge
              onClick={this.handleOnClickCopyPrivateWorkUrl}
            >
              <PrivateWorkFloatingButtonContentContainer>
                <PrivateWorkFloatingButtonIconContainer>
                  <FontAwesomeIcon
                    icon={['fas', 'lock']}
                    color="#FBFBFB"
                    size={appWidth >= deviceSizes.laptop ? 60 : 28}
                  />
                </PrivateWorkFloatingButtonIconContainer>
                <div>
                  <PrivateWorkFloatingButtonTitle>
                    {t('copyPrivateWorkUrlFloatingButtonTitle')}
                  </PrivateWorkFloatingButtonTitle>
                  {appWidth >= deviceSizes.laptop && (
                    <PrivateWorkFloatingButtonDescription>
                      {t('copyPrivateWorkUrlFloatingButtonDescription')}
                    </PrivateWorkFloatingButtonDescription>
                  )}
                </div>
              </PrivateWorkFloatingButtonContentContainer>
            </PrivateWorkFloatingButton>
          </CopyToClipboard>
        )}
        {hasTiktokVideo && (
          <Helmet>
            <script async src="https://www.tiktok.com/embed.js" />
          </Helmet>
        )}
      </WorkNotePageWrap>
    );
  }
}

export default translate('workPage')(
  connect((state, props) => {
    const { works, user } = state;
    const { match } = props;
    const { work_id } = match.params;
    const work = {
      loading: true,
      ...works[work_id],
    };
    return {
      user,
      works,
      work_id,
      work,
      error: work && work.error,
      ...props.initialData,
      match,
    };
  })(withResponsive(errorHandler(WorkPage))),
);
