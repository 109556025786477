/* eslint-disable no-underscore-dangle, no-undef */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import createStore from 'services/redux/store';
import App from './App';
import routes from './routes';

const store = createStore(window.__PRELOADED_STATE__);

hydrate(
  <BrowserRouter>
    <App
      store={store}
      routes={routes}
      initialData={window.__PRELOADED_DATA__}
      extraData={window.__PRELOADED_EXTRA_DATA__}
    />
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
