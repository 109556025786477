import React from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { OutlineButton } from 'components/Buttons';
import ProfileSNSLinks from 'components/UserPage/profileSnsLinks';
import CircleAvatar from 'components/CircleAvatar';
import BasicLoader from 'components/BasicLoader';
import { translate } from 'util/Translator';
import { withResponsive, deviceSizes } from 'util/Responsive';
import truncateString from 'util/truncateString';

const Container = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 30px;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ appWidth }) => (appWidth ? `${appWidth}px` : '300px')};
  opacity: 0.8;
`;

// eslint-disable-next-line no-lone-blocks
{
  /* TODO - CHANGE THESE TO USE HEX VALUES */
}
const BackgroundImageGradient = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  width: 100%;
  height: 100%;
  transform: translateY(1px);
  background: ${({ rgbValues }) =>
    `linear-gradient(
    180deg,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 0) 40%,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 1) 85%,
    rgba(${rgbValues?.r || 255}, ${rgbValues?.g || 255}, ${rgbValues?.b ||
      255}, 1) 100%
  );`};
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ProfileContentContainer = styled.div`
  display: flex;
  padding-top: 25px;
  margin-top: 43%;
  flex-direction: row;
  width: 100%;
  z-index: 0;
`;

const ProfileContentSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 20px 0;
`;

const ProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileName = styled.div`
  margin-bottom: 4px;
  width: 100%;
  font-size: 43px;
  font-weight: bold;
  margin: 0px;
  word-break: break-all;
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'white' : '#222222'};
  font-family: 'Noto Sans JP', sans-serif;
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileInfo = styled.div`
  font-size: 16px;
  font-style: medium;
  line-height: normal;
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'rgb(221, 221, 221)' : '#828282'};
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: 'Noto Sans JP', sans-serif;
`;

const BioContainer = styled.div``;

const Bio = styled.div`
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 23px;
  font-size: 13px;
  color: #333333;
`;

const ShowMoreButton = styled.button`
  display: inline;
  cursor: pointer;
  background-color: transparent;
  color: #5863f8;
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  padding: 0;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const ProfileImage = styled.div`
  margin-bottom: 17px;
`;

const ContactButton = styled(OutlineButton)`
  flex: 1;
  margin-top: 22px;
  color: #828282;
  border: 1px solid #e3e3e3;
`;

const StyledBioLinkify = styled(Linkify)`
  color: ${({ chosenTextColor }) =>
    chosenTextColor === 'white' ? 'white' : '#424242'};

  color: ${({ isDefaultLayout }) => isDefaultLayout && '#33333'};

  display: inline;
`;

class ProfileMobile extends React.Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  handleOnPressOpenEmail = () => {
    const { data } = this.props;
    window.location = `mailto:${data.contact_email}`;
  };

  render() {
    const {
      loading,
      data,
      user,
      appWidth,
      t,
      onPressOpenProfileModal,
    } = this.props;
    const { isMounted } = this.state;
    const rgbValues = user?.rgbValues;
    const chosenTextColor = user?.chosenTextColor;
    const SNSLinks = [
      ['twitter', data?.twitter_url],
      ['youtube', data?.youtube_url],
      ['instagram', data?.instagram_url],
      ['facebook', data?.facebook_url],
      ['website', data?.website],
    ].filter(link => link[1]);

    const getCurrentHeaderImage = () => {
      if (user?.data?.header_data?.header_image_type === 'work') {
        return user?.data?.header_data?.header_work_image;
      }

      return (
        user?.data?.header_data?.header_profile_image ||
        user?.data?.avatar?.phone
      );
    };

    return (
      <Container>
        {loading ? (
          <BasicLoader />
        ) : (
          <div>
            <BackgroundImage
              image={
                getCurrentHeaderImage() ||
                'https://dyci7co52mbcc.cloudfront.net/static/images/default_avatar.jpg'
              }
              appWidth={isMounted && appWidth}
            >
              <BackgroundImageGradient rgbValues={rgbValues} />
            </BackgroundImage>
            <ProfileContainer>
              <ProfileContentContainer>
                <ProfileContentSubContainer>
                  <ProfileImage>
                    <CircleAvatar
                      image={data && data.avatar && data.avatar.thumb}
                      size={53}
                    />
                  </ProfileImage>
                  <ProfileNameContainer>
                    {!!data.profession && (
                      <ProfileInfoContainer>
                        <ProfileInfo chosenTextColor={chosenTextColor}>
                          {data.profession}
                        </ProfileInfo>
                      </ProfileInfoContainer>
                    )}
                    <ProfileName chosenTextColor={chosenTextColor}>
                      {data.name}
                    </ProfileName>
                  </ProfileNameContainer>
                  {!!data.location && (
                    <ProfileInfoContainer>
                      <ProfileInfo chosenTextColor={chosenTextColor}>
                        {data.location}
                      </ProfileInfo>
                    </ProfileInfoContainer>
                  )}
                  <ProfileSNSLinks
                    t={t}
                    userData={data}
                    chosenTextColor={user?.chosenTextColor}
                    isDefaultLayout
                    // isCurrentUser={isCurrentUser}
                    SNSLinks={SNSLinks}
                  />
                  {!!(data.bio && data.bio.length > 0) && (
                    <BioContainer>
                      <Bio>
                        <StyledBioLinkify
                          chosenTextColor={user?.chosenTextColor}
                          properties={{ target: '_blank' }}
                        >
                          {truncateString(
                            data.bio.split('\n', 3).join('\n'),
                            100,
                          )}
                        </StyledBioLinkify>

                        {!!(data.bio && data.bio.length > 50) && (
                          <ShowMoreButton
                            onClick={onPressOpenProfileModal}
                            chosenTextColor={user?.chosenTextColor}
                          >
                            {t('showMore')}
                          </ShowMoreButton>
                        )}
                      </Bio>
                    </BioContainer>
                  )}
                  {!!data.contact_email && (
                    <ContactButton
                      fullyRound
                      icon={
                        <FontAwesomeIcon
                          icon={['fas', 'hand-paper']}
                          color="#828282"
                          size={16}
                        />
                      }
                      text={t('contact')}
                      onPress={this.handleOnPressOpenEmail}
                    />
                  )}
                </ProfileContentSubContainer>
              </ProfileContentContainer>
            </ProfileContainer>
          </div>
        )}
      </Container>
    );
  }
}

export default withResponsive(translate('userPage')(ProfileMobile));
