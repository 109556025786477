import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import BasicLoader from 'components/BasicLoader';

const AnimatedContainer = styled(animated.div)`
  position: relative;
  height: 100%;
  cursor: zoom-in;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
  background-color: #c0c0c0;
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: white;
`;

const StyledBasicLoader = styled(BasicLoader)`
  height: auto;
`;

const WorkImage = ({ src, alt, title, srcset, width, height }) => {
  const imageRef = useRef();
  const [loaded, toggleLoaded] = useState(false);

  const transitionStyle = useSpring({
    config: { duration: 300 },
    opacity: loaded ? 1 : 0.3,
  });

  const handleOnLoadNewImage = () => {
    if (!loaded) {
      toggleLoaded(true);
    }
  };

  useEffect(() => {
    if (imageRef.current?.complete) {
      handleOnLoadNewImage();
    }
  }, []);

  return (
    <AnimatedContainer style={transitionStyle}>
      <StyledImage
        src={src}
        srcSet={srcset}
        alt={alt}
        title={title}
        ref={imageRef}
        onLoad={handleOnLoadNewImage}
        onError={() => toggleLoaded(true)}
        width={width}
        height={height}
      />
      {!loaded && (
        <LoaderContainer>
          <StyledBasicLoader innerContainerStyle={{ margin: '0 auto' }} />
        </LoaderContainer>
      )}
    </AnimatedContainer>
  );
};

export default WorkImage;
