import { LOCALE_SET } from 'services/constants/actionTypes';

export default function locale(state = 'en', action) {
  switch (action.type) {
    case LOCALE_SET: {
      return action.locale;
    }
    default:
      return state;
  }
}
