import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';

const supportedPlatforms = ['niconico', 'sketchfab'];

const PlayerContainer = styled.div`
  background-color: #000;
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 10px;
  width: 100%;
`;

const renderThreeDModelWork = work => {
  if (!supportedPlatforms.includes(work.platform)) {
    return null;
  }
  let src;
  switch (work.platform) {
    case 'niconico':
      src = `https://3d.nicovideo.jp/externals/embedded?id=${work.model_id}`;
      break;
    case 'sketchfab':
      src = `https://sketchfab.com/models/${work.model_id}/embed`;
      break;
    default:
      break;
  }
  return (
    <iframe
      src={src}
      title={work.model_id}
      width="100%"
      height="100%"
      frameBorder={0}
      allowFullScreen
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    />
  );
};

const ThreeDModelWorks = pure(({ models }) => (
  <>
    {models.map(model => {
      return <PlayerContainer>{renderThreeDModelWork(model)}</PlayerContainer>;
    })}
  </>
));

export default ThreeDModelWorks;
