import Cookies from 'universal-cookie';

let cookies = new Cookies();

export const getCookies = () => {
  return cookies;
};

export const initCookies = serverCookies => {
  cookies = new Cookies(serverCookies);
};
