import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;
    let dontScroll = false;
    const params = location.pathname.split('/');
    const prevParams = prevLocation.pathname.split('/');
    if (params.length > 2 && prevParams.length > 2) {
      if (
        params[1] === 'works' &&
        prevParams[1] === 'works' &&
        params[2] === prevParams[2]
      ) {
        dontScroll = true;
      }
    }
    if (location !== prevProps.location && !dontScroll) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
