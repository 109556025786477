import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Error404 from 'components/ErrorPage/Error404';
import Error403 from 'components/ErrorPage/Error403';
import { getCookies } from 'util/cookies';

const errorHandler = WrappedComponent => {
  const HOC = class extends Component {
    static getDerivedStateFromProps(props, state) {
      const { error } = props;
      return {
        ...state,
        stateError: error,
      };
    }

    constructor(props) {
      super(props);
      this.cookies = getCookies();
      this.state = {
        stateError: null,
      };
    }

    render() {
      const { staticContext, location, match } = this.props;
      const { stateError } = this.state;
      if (stateError) {
        if (stateError.code === 'p') {
          if (!staticContext) {
            this.cookies.set('locationState', {
              from: location,
              fromMatch: match,
              user: stateError.user,
            });
          }
          return (
            <Redirect
              to={{
                pathname: '/private',
                search: `?from=${location.pathname}`,
                state: {
                  from: location,
                  fromMatch: match,
                  user: stateError.user,
                },
              }}
            />
          );
        }
        let ErrorComponent;
        switch (stateError.status) {
          case 403:
            ErrorComponent = Error403;
            break;
          case 404:
          default:
            ErrorComponent = Error404;
            break;
        }
        return <ErrorComponent staticContext={staticContext} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
  hoistNonReactStatic(HOC, WrappedComponent);
  return HOC;
};

export default errorHandler;
