import React from 'react';
import { pure } from 'recompose';
import styled, { css } from 'styled-components';
import {
  LazyLoadComponent,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import WorkImage from 'components/WorkPage/WorkImage';
import Lightbox, { useLightbox } from 'components/Lightbox';
import { withResponsive } from 'util/Responsive';
import 'react-image-lightbox/style.css';

const ImageContainer = styled.div`
  cursor: pointer;
  ${({ index }) =>
    index > 0 &&
    css`
      margin-top: 10px;
    `}
`;

const ImageWorks = pure(({ images, workTitle, scrollPosition }) => {
  const {
    selectedLightboxIndex,
    setSelectedLightboxIndex,
    isOpenLightbox,
    toggleLightbox,
  } = useLightbox();
  return (
    <>
      {images.map((image, index) => {
        return (
          <ImageContainer
            key={image.id}
            index={index}
            onClick={() => {
              setSelectedLightboxIndex(index);
              toggleLightbox(true);
            }}
          >
            <LazyLoadComponent
              scrollPosition={scrollPosition}
              visibleByDefault={index === 0}
            >
              <WorkImage
                src={image.url}
                srcset={image.srcset}
                width={image.width}
                height={image.height}
                alt={`${workTitle}-${index + 1}`}
              />
            </LazyLoadComponent>
          </ImageContainer>
        );
      })}
      {isOpenLightbox && (
        <Lightbox
          images={images.map((image, index) => ({
            caption: `${index + 1} / ${images.length}`,
            src: image.url,
          }))}
          selectedLightboxIndex={selectedLightboxIndex}
          setSelectedLightboxIndex={setSelectedLightboxIndex}
          isOpenLightbox={isOpenLightbox}
          toggleLightbox={toggleLightbox}
        />
      )}
    </>
  );
});

export default withResponsive(trackWindowScroll(ImageWorks));
