import validateForiioDomain from 'util/validateForiioDomain';

export const getBaseUrl = staticContext => {
  if (staticContext && staticContext.baseUrl) {
    return staticContext.baseUrl;
  }
  if (typeof window !== 'undefined') {
    return `${window.location.protocol || 'https'}//${window.location.host ||
      'www.foriio.com'}`;
  }
  return '';
};

export const getForiioBaseUrl = staticContext => {
  if (staticContext && staticContext.baseUrl) {
    const { baseUrl, protocol } = staticContext;
    if (validateForiioDomain(baseUrl)) {
      return {
        url: `${protocol}://${baseUrl
          .split('.')
          .slice(1)
          .join('.')}`,
        isExternalUrl: true,
      };
    }
  }
  if (typeof window !== 'undefined') {
    if (validateForiioDomain(window.location.host)) {
      return {
        url: `${window.location.protocol}//${window.location.host
          .split('.')
          .slice(1)
          .join('.')}`,
        isExternalUrl: true,
      };
    }
  }
  return {
    isExternalUrl: false,
  };
};

export const getOGUrl = staticContext => {
  if (staticContext && staticContext.reqUrl) {
    return staticContext.reqUrl;
  }
  return `${window.location.origin}${window.location.pathname}`;
};
