import {
  USER_WORKS_LOAD,
  SINGLE_WORK_FETCH,
  SINGLE_WORK_LOAD,
  SINGLE_WORK_ERROR,
  SINGLE_WORK_CLEAR,
  SINGLE_WORK_NOTESLIST_FETCH,
  SINGLE_WORK_NOTESLIST_LOAD,
  SINGLE_WORK_NOTESLIST_ERROR,
  RELATED_NOTESLIST_FETCH,
  RELATED_NOTESLIST_LOAD,
  RELATED_NOTESLIST_ERROR,
} from 'services/constants/actionTypes';

export default function works(state = {}, action) {
  switch (action.type) {
    case USER_WORKS_LOAD: {
      const newWorks = action.works.reduce(
        (acc, work) => ({
          ...acc,
          [work.id]: {
            ...state[work.id],
            data: {
              ...(state[work.id] && state[work.id].data),
              ...work,
            },
          },
        }),
        {},
      );
      return {
        ...state,
        ...newWorks,
      };
    }
    case SINGLE_WORK_FETCH: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loading: true,
        },
      };
    }
    case SINGLE_WORK_LOAD: {
      const { workID, data } = action;
      return {
        ...state,
        [workID]: {
          notesList: [],
          ...state[workID],
          data,
          loading: false,
        },
      };
    }
    case SINGLE_WORK_ERROR: {
      const { workID, error } = action;
      return {
        ...state,
        [workID]: {
          notesList: [],
          ...state[workID],
          loading: false,
          error,
        },
      };
    }
    case SINGLE_WORK_CLEAR: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          notesList: [],
          ...state[workID],
          loading: false,
          error: null,
        },
      };
    }
    case SINGLE_WORK_NOTESLIST_FETCH: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loadingNotesList: true,
        },
      };
    }
    case SINGLE_WORK_NOTESLIST_LOAD: {
      const { workID, data } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loadingNotesList: false,
          notesList: data.reverse(),
        },
      };
    }
    case SINGLE_WORK_NOTESLIST_ERROR: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loadingNotesList: false,
        },
      };
    }
    case RELATED_NOTESLIST_FETCH: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loadingRelatedNotesList: true,
        },
      };
    }
    case RELATED_NOTESLIST_LOAD: {
      const { workID, data } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          relatedNotesList: data,
          loadingRelatedNotesList: false,
        },
      };
    }
    case RELATED_NOTESLIST_ERROR: {
      const { workID } = action;
      return {
        ...state,
        [workID]: {
          ...state[workID],
          loadingRelatedNotesList: false,
        },
      };
    }

    default:
      return state;
  }
}
