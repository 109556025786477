import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import template from 'lodash.template';
import hoistNonReactStatic from 'hoist-non-react-statics';

const { Provider, Consumer } = React.createContext({
  t: translateKey => translateKey,
});

const translator = (
  translateKey = '',
  interpolate,
  pageKey = '',
  data = {},
  locale = '',
) => {
  if (Array.isArray(pageKey)) {
    const result = pageKey.reduce((acc, cur) => {
      acc[cur] = data[locale][cur];
      return acc;
    }, {});
    // support namespace
    // translate(['roleModal', 'workPage'])
    // t('workPage.addCredit);
    const string = get(result, translateKey, translateKey);
    if (interpolate) {
      return template(string)(interpolate);
    }
    return string;
  }
  const string =
    ((data[locale] || {})[pageKey] || {})[translateKey] || translateKey;
  if (interpolate) {
    // support interpolate in template
    // "relatedWorks": "More works from ${user}"
    // t('relatedWorks',  { user: profile.name })
    return template(string)(interpolate);
  }
  return string;
};

const UcTranslationProvider = ({ children, data, locale }) => (
  <Provider
    value={{
      t: pageKey => (translateKey, interpolate) =>
        translator(translateKey, interpolate, pageKey, data, locale),
      locale,
    }}
  >
    {children}
  </Provider>
);

const mapLocale = ({ locale }) => ({
  locale,
});

export const TranslationProvider = connect(mapLocale)(UcTranslationProvider);

export const translate = pageKey => WrappedComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  class Translate extends React.Component {
    render() {
      return (
        <Consumer>
          {({ t, locale }) => (
            <WrappedComponent {...{ t: t(pageKey), locale, ...this.props }} />
          )}
        </Consumer>
      );
    }
  }
  hoistNonReactStatic(Translate, WrappedComponent);
  return Translate;
};
