import React, { useState } from 'react';
import RLightbox from 'react-image-lightbox';

export const useLightbox = () => {
  const [selectedLightboxIndex, setSelectedLightboxIndex] = useState(0);
  const [isOpenLightbox, toggleLightbox] = useState(false);

  return {
    selectedLightboxIndex,
    setSelectedLightboxIndex,
    isOpenLightbox,
    toggleLightbox,
  };
};

const Lightbox = ({
  images,
  selectedLightboxIndex,
  setSelectedLightboxIndex,
  toggleLightbox,
  ...restProps
}) => {
  return (
    <RLightbox
      mainSrc={images[selectedLightboxIndex].src}
      nextSrc={images[(selectedLightboxIndex + 1) % images.length].src}
      prevSrc={
        images[(selectedLightboxIndex + images.length - 1) % images.length].src
      }
      imageCaption={images[selectedLightboxIndex].caption}
      onCloseRequest={() => toggleLightbox(false)}
      onMovePrevRequest={() =>
        setSelectedLightboxIndex(
          (selectedLightboxIndex + images.length - 1) % images.length,
        )
      }
      onMoveNextRequest={() =>
        setSelectedLightboxIndex((selectedLightboxIndex + 1) % images.length)
      }
      reactModalStyle={{
        overlay: {
          zIndex: 10000,
        },
      }}
      {...restProps}
    />
  );
};

export default Lightbox;
