import React from 'react';
import styled from 'styled-components';
import WorkGrid from 'components/UserPage/WorkGrid';
import { withResponsive, deviceSizes, media } from 'util/Responsive';
import { translate } from 'util/Translator';

const Container = styled.div`
  background-color: #fbfbfb;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const SubContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  align-self: center;
  width: 1092px;
  flex-grow: 1;
  margin: 0 auto;
  ${media.laptop`
    width: 100%;
  `}
`;

const SubSubContainer = styled.div`
  ${media.tablet`
    margin-left: 20px;
    margin-right: 20px;
  `}
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 30px;
`;

const RelatedWorks = ({ t, appWidth, data }) => {
  const { author, related_works } = data;
  return (
    <Container>
      <SubContainer>
        <SubSubContainer>
          <Title>{t('relatedWorks', { user: author.profile.name })}</Title>
          <WorkGrid
            data={related_works}
            horizontal={appWidth <= deviceSizes.tablet}
          />
        </SubSubContainer>
      </SubContainer>
    </Container>
  );
};

export default withResponsive(translate('workPage')(RelatedWorks));
