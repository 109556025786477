import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

const Button = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-height: 35px;
  padding: 10px 30px;
  border-radius: ${({ fullyRound }) => (fullyRound ? '999px' : '5px')};
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  word-wrap: break-word;
  white-space: pre;
  color: ${({ textColor }) => textColor || 'white'};
  transition: background-color 0.3s, color 0.3s;
  ${({ withBorder }) =>
    withBorder
      ? css`
          border-width: 1px;
          border-style: solid;
          border-color: #e0e0e0;
        `
      : css`
          border: none;
        `}
  background-color: rgb(16, 124, 232);
  :hover {
    background-color: rgb(13, 110, 206);
  }
  :active {
    background-color: rgb(11, 101, 190);
  }
  :disabled {
    pointer-events: none;
    background-color: rgb(229, 229, 229);
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ noMargin, right }) =>
    !noMargin && `margin-${right ? 'left' : 'right'}: 5px;`}
  border: none;
`;

const BlueButton = ({
  className,
  style,
  fullyRound,
  withBorder,
  icon,
  rightIcon,
  text,
  textColor,
  hoverTextColor,
  disabled,
  children,
  hovered,
  onPress,
  onClick,
  iconContainerStyle,
}) => {
  return (
    <Button
      className={className}
      style={style}
      textColor={textColor}
      disabled={disabled}
      fullyRound={fullyRound}
      withBorder={withBorder}
      onClick={onClick || onPress}
    >
      <Fragment>
        {!text && (
          <IconContainer noMargin style={iconContainerStyle}>
            {React.cloneElement(icon, {
              color: hovered ? hoverTextColor || textColor : textColor,
            })}
          </IconContainer>
        )}
        {!text && children}
        {text && (
          <Fragment>
            {icon && (
              <IconContainer style={iconContainerStyle}>
                {React.cloneElement(icon, {
                  color: hovered ? hoverTextColor || textColor : textColor,
                })}
              </IconContainer>
            )}
            {text}
          </Fragment>
        )}
        {rightIcon && (
          <IconContainer right style={iconContainerStyle}>
            {React.cloneElement(rightIcon, {
              color: hovered ? hoverTextColor || textColor : textColor,
            })}
          </IconContainer>
        )}
      </Fragment>
    </Button>
  );
};

const MintBlueButton = styled(BlueButton)`
  background-color: #69d1c5;
  :hover {
    background-color: #69ccd1;
  }
  :active {
    background-color: #69c3d1;
  }
`;

const GreenButton = styled(BlueButton)`
  background-color: rgb(39, 174, 96);
  :hover {
    background-color: rgb(35, 160, 88);
  }
  :active {
    background-color: rgb(30, 143, 78);
  }
`;

const LightGreenButton = styled(BlueButton)`
  background-color: rgb(111, 207, 151);
  :hover {
    background-color: rgb(102, 194, 140);
  }
  :active {
    background-color: rgb(91, 177, 126);
  }
`;

const RedButton = styled(BlueButton)`
  background-color: rgb(235, 87, 87);
  :hover {
    background-color: rgb(219, 80, 80);
  }
  :active {
    background-color: rgb(204, 73, 73);
  }
`;

const OrangeButton = styled(BlueButton)`
  background-color: rgb(242, 153, 74);
  :hover {
    background-color: rgb(228, 144, 70);
  }
  :active {
    background-color: rgb(212, 133, 63);
  }
`;

const GrayButton = styled(BlueButton)`
  color: #4f4f4f;
  background-color: rgb(251, 251, 251);
  border: 1px solid #e0e0e0;
  :hover {
    background-color: rgb(255, 255, 255);
  }
  :active {
    background-color: rgb(255, 255, 255);
  }
`;

const WhiteButton = styled(BlueButton)`
  color: #4f4f4f;
  background-color: rgb(251, 251, 255);
  border: 1px solid #e0e0e0;
  :hover {
    background-color: rgb(255, 255, 255);
  }
  :active {
    background-color: rgb(255, 255, 255);
  }
`;

const OutlineButton = styled(BlueButton)`
  color: #e3e3e3;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  :hover {
    background-color: rgb(255, 255, 255);
    color: #000;
  }
  :active {
    background-color: rgb(255, 255, 255);
    color: #000;
  }
`;

const TextButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  background-color: transparent;
  color: #107ce8;
  ${({ hidden }) => hidden && 'opacity: 0;'}
`;

const ModalCircleIconButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalCircleIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 45px;
  width: 45px;
  height: 45px;
  margin-bottom: 7px;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const ModalCircleIconText = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.04em;
  color: #333333;
`;

const ModalCircleIconButton = ({
  icon,
  text,
  className,
  textStyle,
  onPress,
}) => (
  <ModalCircleIconButtonContainer onClick={onPress}>
    <ModalCircleIconContainer className={className}>
      {icon}
    </ModalCircleIconContainer>
    <ModalCircleIconText textStyle={textStyle}>{text}</ModalCircleIconText>
  </ModalCircleIconButtonContainer>
);

export {
  BlueButton,
  MintBlueButton,
  GreenButton,
  LightGreenButton,
  RedButton,
  OrangeButton,
  GrayButton,
  WhiteButton,
  OutlineButton,
  TextButton,
  ModalCircleIconButton,
};
