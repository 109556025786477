import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FitImage from 'components/FitImage';
import Divider from 'components/Divider';
import { translate } from 'util/Translator';

const NoteDivider = styled(Divider)`
  border-color: #f2f2f2;
  margin: 20px 0;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const NoteInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NoteTitle = styled.div`
  font-family: 'Noto Sans JP';
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.03em;
  margin-bottom: 5px;
  color: #222222;
`;

const NoteLink = styled(Link)`
  display: flex;
`;

const RelatedNoteWorkContainer = styled.div`
  margin-bottom: 10px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RelatedNoteWorkTitleFromWork = styled.span`
  font-family: 'Noto Sans JP';
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #828282;
`;

const RelatedNoteWorkTitle = styled.span`
  font-family: 'Noto Sans JP';
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #222222;
  margin-left: 8px;
`;

const NoteThumbnailAndInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoteThumbnailContainer = styled.div`
  position: relative;
  width: 90px;
  height: 60px;
  margin-right: 10px;
`;

const ListShowMoreTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ReadMoreText = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: rgb(88, 99, 248);
  margin: 0px;
  cursor: pointer;
`;

const StyledFitImage = styled(FitImage)`
  max-width: 90px;
  border-radius: 5px;
`;

const Note = ({ t, note, work, onClickViewNote, isRelatedNote }) => (
  <NoteLink
    to={`/works/${isRelatedNote ? note.work.id : work.id}/notes/${note.id}${
      work.secret && !isRelatedNote ? `?secret=${work.secret}` : ''
    }`}
    onClick={onClickViewNote}
    key={note.id}
  >
    {isRelatedNote && (
      <RelatedNoteWorkContainer>
        <RelatedNoteWorkTitleFromWork>
          {t('fromWork')}
        </RelatedNoteWorkTitleFromWork>
        <RelatedNoteWorkTitle>{note.work.title}</RelatedNoteWorkTitle>
      </RelatedNoteWorkContainer>
    )}

    <NoteThumbnailAndInfoContainer>
      {note.thumbnail && (
        <StyledFitImage
          src={note.thumbnail}
          customContainer={NoteThumbnailContainer}
          alt={note.title}
        />
      )}
      <ListShowMoreTextContainer>
        <NoteInfoContainer>
          <NoteTitle>{note.title}</NoteTitle>
        </NoteInfoContainer>
        <ReadMoreText>Read more</ReadMoreText>
      </ListShowMoreTextContainer>
    </NoteThumbnailAndInfoContainer>
  </NoteLink>
);

const WorkNoteList = props => {
  const { t, work, notes, onClickViewNote, isRelatedNote, className } = props;
  return (
    <NotesContainer className={className}>
      {notes.map((note, index) => (
        <Fragment key={note.id}>
          <Note
            t={t}
            note={note}
            work={work}
            onClickViewNote={onClickViewNote}
            isRelatedNote={isRelatedNote}
          />
          {index < notes.length - 1 && <NoteDivider />}
        </Fragment>
      ))}
    </NotesContainer>
  );
};

export default translate('notes')(WorkNoteList);
