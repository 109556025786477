import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import DOMPurify from 'dompurify';
import FullWidthAspectImage from 'components/AspectImage';
import useIsMounted from 'util/useIsMounted';

const textDefaultStyle = css`
  letter-spacing: 0.03em;
  line-height: 1.8em;
  word-break: break-word;
  color: #4f4f4f;
`;

const Container = styled.div`
  p {
    ${textDefaultStyle}
  }
  span {
    ${textDefaultStyle}
  }
  block {
    margin-bottom: 20px;
    display: inline;
  }
  blockquote {
    border-left: 5px solid #aaaaaa;
    background-color: transparent;
    padding: 10px 10px 0 15px;
    margin: 0;
  }
  h1 {
    ${textDefaultStyle}
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  h2 {
    ${textDefaultStyle}
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h3 {
    ${textDefaultStyle}
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h4 {
    ${textDefaultStyle}
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h5 {
    ${textDefaultStyle}
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h6 {
    ${textDefaultStyle}
    font-size: 12px;
    margin-top: -18px;
    margin-bottom: 15px;
  }
  hr {
    display: flex;
    align-self: stretch;
    height: 1px;
    background-color: #333333;
    margin: 8px 0;
  }
  text: {
    ${textDefaultStyle}
    align-self: flex-start;
    font-size: 16px;
  }
  strong: {
    ${textDefaultStyle}
    font-weight: bold;
  }
  em {
    ${textDefaultStyle}
    font-style: italic;
  }
  del {
    text-decoration-line: line-through;
  }
  u {
    text-decoration-line: underline;
  }
  a {
    display: inline;
    color: #4f4f4f;
    text-decoration: underline;
  }
  li {
    color: #4f4f4f;
  }
  ol {
    color: #4f4f4f;
  }
`;

const Heading = styled.h1``;

const transformAnchorNode = (node, index) => {
  if (node.name === 'a') {
    // eslint-disable-next-line no-param-reassign
    node.attribs.target = '_blank';
    return convertNodeToElement(node, index);
  }
};
const ListItem = ({ children }) => {
  return <li>{children}</li>;
};

const EditorJsViewer = ({ data }) => {
  const isMounted = useIsMounted();
  const renderBlock = block => {
    switch (block.type) {
      case 'header':
        return <Heading as={`h${block.data.level}`}>{block.data.text}</Heading>;
      case 'paragraph':
        return (
          <p>
            {ReactHtmlParser(
              isMounted ? DOMPurify.sanitize(block.data.text) : block.data.text,
              {
                transform: transformAnchorNode,
              },
            )}
          </p>
        );
      case 'code':
        return <pre>{block.data.code}</pre>;
      case 'list': {
        const { style, items } = block.data;
        if (style === 'ordered') {
          return (
            <ol>
              {items.map((item, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <ListItem key={index}>{item}</ListItem>;
              })}
            </ol>
          );
        }
        return (
          <ul>
            {items.map((item, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <ListItem key={index}>{item}</ListItem>;
            })}
          </ul>
        );
      }
      case 'image':
        return (
          <>
            <FullWidthAspectImage
              src={block.data.file.url}
              alt={block.data.caption}
              title={block.data.caption}
            />
            {block.data.caption && <p>{block.data.caption}</p>}
          </>
        );
      default:
        return '';
    }
  };
  return (
    <Container>
      {data.blocks.map((block, index) => {
        return <Fragment key={index}>{renderBlock(block)}</Fragment>;
      })}
    </Container>
  );
};

export default EditorJsViewer;
