import React, { Fragment } from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import FullWidthAspectImage from 'components/AspectImage';
import { mediaMin } from 'util/Responsive';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px;
`;

const CardContent = styled.div`
  padding: 22px;
`;

const CardTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 21px;
  color: #4f4f4f;
  margin-bottom: 10px;
  word-break: break-word;
`;

const CardDescription = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  margin-bottom: 30px;
  word-break: break-word;
`;

const CardUrl = styled.div`
  font-size: 13px;
  font-weight: bolder;
  line-height: 16px;
  color: #bdbdbd;
  word-break: break-all;
`;

const StyledLink = styled.a`
  ${mediaMin.tablet`
    max-width: 60%;
  `}
`;

const WebWorks = pure(props => {
  const { articles, workTitle } = props;
  return (
    <Fragment>
      {articles.map(article => (
        <StyledLink
          key={article.url}
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card>
            <FullWidthAspectImage src={article.image} alt={workTitle} />
            <CardContent>
              <CardTitle>{article.title}</CardTitle>
              <CardDescription>{article.description}</CardDescription>
              <CardUrl>{article.url}</CardUrl>
            </CardContent>
          </Card>
        </StyledLink>
      ))}
    </Fragment>
  );
});

export default WebWorks;
