import { USER_WORKS_CATEGORIES_LOAD } from 'services/constants/actionTypes';

export default function workCategories(state = {}, action) {
  switch (action.type) {
    case USER_WORKS_CATEGORIES_LOAD: {
      const newWorksCategories = action.data.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: { data: category },
        }),
        {},
      );
      return {
        ...state,
        ...newWorksCategories,
      };
    }
    default:
      return state;
  }
}
