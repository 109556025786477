import {
  NOTE_FETCH,
  NOTE_LOAD,
  NOTE_ERROR,
} from 'services/constants/actionTypes';

export default function notes(state = {}, action) {
  switch (action.type) {
    case NOTE_FETCH: {
      const { workID, noteID } = action;
      const noteKey = `${workID}:${noteID}`;
      return {
        ...state,
        [noteKey]: {
          ...state[noteKey],
          loading: true,
        },
      };
    }
    case NOTE_LOAD: {
      const { workID, noteID, data } = action;
      const noteKey = `${workID}:${noteID}`;
      return {
        ...state,
        [noteKey]: {
          ...(state[noteKey] || {}),
          loading: false,
          data,
        },
      };
    }
    case NOTE_ERROR: {
      const { workID, noteID } = action;
      const noteKey = `${workID}:${noteID}`;
      return {
        ...state,
        [noteKey]: {
          ...state[noteKey],
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}
