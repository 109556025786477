import { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    // Duplicated error on dev environment
    // https://docs.sentry.io/clients/javascript/integrations/react/#expanded-usage
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
